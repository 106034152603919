// GO TOP
#go_top {
  position: fixed;
  bottom: 50px;
  right: -45px;
  z-index: 99;
  width: 45px;
  height: 45px;
  font-size: 18px;
  text-align: center;
  background: $brand-danger;
  color: #fff;
  cursor: pointer;
  border-radius: 3px 0 0 3px;
  transition: all .3s ease;

  &:before {
    @include fa("\f077");
    @include center();
  }

  &:hover {
    background: darken($brand-danger, 15%);
  }
}

// STAR RATING
.star-rating {
  display: inline-block;
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1;
  font-size: 1em;
  width: 4.7em;
  vertical-align: middle;

  &:before {
    width: 100%;
    display: block;
  }

  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 1em;
    overflow: hidden;
    color: $brand-danger;

    &:before {
      @include fa("\f005\f005\f005\f005\f005");
    }
  }
}

// TITLE BOX
.maxus-titlebox {
  position: relative;
}

.maxus-titlebox__title {
  margin-bottom: 20px;
}

// SIZE
.maxus-size {
  position: relative;
  padding: 7px 0;

  &.btn {
    padding: 6px 10px 5px;
    line-height: 1;
  }

  &.btn-rounded {
    border-radius: 100px !important;
  }

  label {
    display: inline-block;
    margin: 0;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;

    &:not(:last-child) {
      margin-right: 3px;
    }

    &:hover span {
      background: #f0f0f0;
    }
  }

  input {
    display: none;
  }

  span {
    display: block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-transform: uppercase;
    color: black;
    transition: all .25s ease;
  }

  input:checked ~ span {
    background: $primary-color;
    color: white;
  }
}

// COLOR
.maxus-color {
  position: relative;
  min-width: 0;
  line-height: 8px;
  padding: 7px 10px;

  &.btn-rounded {
    border-radius: 100px !important;
  }

  label {
    display: inline-block;
    margin: 0;
    text-align: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 3px;
    }

    &:hover span {
      background: #f0f0f0;
    }
  }

  input {
    display: none;
  }

  &[data-style="round"] span,
  span {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    overflow: hidden;
    font-size: 0;
    text-transform: uppercase;
    color: black;
    background: $primary-color;
    transition: all .25s ease;
  }

  input:checked ~ span {
    &:before {
      font-size: 14px;
      color: white;
      transform: translateY(-50%);

      @include fa("\f00c");
      @include absolute(50%,null,null,5px);
    }
  }

  &[data-style="square"] {
    border-color: transparent;
    padding-left: 0;
    padding-right: 0;

    &:hover, &:active:hover {
      border-color: transparent;
    }

    span {
      border-radius: 0;
    }
  }
}

// QUANTITY
.maxus-quantity {
  position: relative;
  min-width: 0;
  line-height: 8px;
  padding: 8px 30px 9px;

  &.btn-rounded {
    border-radius: 100px !important;
  }

  .ui-spinner {
    position: relative;
    display: block;
  }

  input[type="text"],
  input[type="number"] {
    height: auto;
    padding: 0;
    border: none;
    box-shadow: none;
    width: 45px;
    background: none;
    text-align: center;
  }

  .ui-spinner-button {
    font-size: 14px;
    color: $text-color;
    cursor: pointer;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    transform: translateY(-50%);

    @include absolute(50%);

    &:hover {
      color: black;
    }
  }

  .ui-spinner-down {
    left: -25px;

    &:before {
      content: "-";
    }
  }

  .ui-spinner-up {
    right: -25px;

    &:before {
      content: "+";
    }
  }
}

// VIDEO
.maxus-video {
  position: relative;
  background-color: #f0f0f0;
  overflow: hidden;

  &:after {
    position: relative;
    z-index: -1;

    @include paddingTop(1920,760);
  }
}

.maxus-video__img {
  z-index: 2;

  @include absolute(0,0,0,0);

  img {
    width: 100%;
  }
}

.maxus-video__play {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: white;
  color: black;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
  transition: all ease 0.3s;

  @include center(null,null,3);

  > i.fa-play {
    margin-left: 2px;
  }

  &:hover,
  &:focus {
    color: white;
    background-color: black;
  }

  @include media('>=sm') {
    width: 72px;
    height: 72px;
    line-height: 72px;
    font-size: 27px;
  }
}

.maxus-video__iframe {
  z-index: 1;
  transition: all .8s ease;

  @include vhidden();
  @include absolute(0,0,0,0);

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  &.open {
    @include vdisplay();
  }
}

// SEARCH PRODUCT
.maxus-search-product {
  position: relative;
  padding: 29px 50px 29px 0;

  @include media(">=sm") {
    margin-left: -10px;
    margin-right: -10px;
    padding: 29px 50px 29px 0;
  }

  @include media(">=md", "<1300px") {
    padding: 22px 50px 21px 0;
  }

  input[type="text"],
  input[type="search"] {
    border: none;
    box-shadow: none;
    padding: 15px 0;
    font-size: 18px;
    height: auto;
  }

  .btn-search {
    background: none !important;
    font-size: 20px;
    color: #111111;
    border: none;
    outline: none !important;
    padding: 0;

    @include absolute(0,15px,0);

    &:hover,
    &:focus,
    &:active,
    &:active:hover {
      color: $primary-color;
    }
  }
}

// SELECT COMPARE ITEMS
.maxus-select-compare {
  padding: 15px 0;
  text-align: center;

  @include media(">=sm") {
    padding: 20px 10px;
    margin-left: -10px;
    margin-right: -10px;
  }

  label, .btn, .maxus-select-compare__list {
    vertical-align: middle;
    margin: 0;
  }

  label {
    display: block;
    margin-bottom: 10px;

    @include media(">=sm") {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 0;
    }

    @include media(">=md", "<1300px") {
      display: none;
    }
  }

  .btn {
    min-width: 0;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 3px;
  }
}

.maxus-select-compare--border {
  @include media(">=md") {
    border-left: 1px solid #e1e1e1;
  }
}

.maxus-select-compare__list {
  display: block;

  @include media(">=sm") {
    display: inline-block;
  }
}

.maxus-select-compare__item {
  position: relative;
  display: inline-block;
  margin-left: -3px;
  width: 50px;
  height: 65px;
  line-height: 65px;
  background-color: #f3f3f3;
  text-align: center;
  margin-bottom: 10px;

  @include media(">=tn") {
    width: 60px;
    height: 75px;
    line-height: 75px;
  }

  @include media(">=sm") {
    float: left;
    margin: 0 10px;
  }

  @include media(">=md", "<1300px") {
    width: 45px;
    height: 60px;
    line-height: 60px;
  }

  &:hover {
    .maxus-select-compare__remove {
      @include vdisplay();
    }
  }
}

.maxus-select-compare__remove {
  color: $brand-danger;
  cursor: pointer;
  transition: all .3s ease;

  @include overlay();
  @include vhidden();
}

// FILTER - SORTING
.maxus-filter-sorting {
  @include media(">=sm") {
    text-align: center;
  }

  @include media(">=1300px") {
    text-align: left;
  }

  .btn-maxus {
    text-transform: none;
    width: 100%;
    min-width: 0;
    border-width: 1px;
    font-size: 12px;
    font-family: $font-family-base;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 300;

    @include media(">=sm") {
      min-width: 116px;
      width: auto;
    }
  }

  label {
    margin: 0;
    margin-right: 10px;
    font-size: 12px;
  }

  .maxus {
    font-size: 17px;
    vertical-align: text-top;
  }
}

.maxus-filter-sorting--border-style {
  padding: 0;
}

.maxus-filter-sorting__item {
  display: block;
  margin-bottom: 15px;

  @include media(">=sm") {
    display: inline-block;
    margin-bottom: 0;
  }

  @include media(">=1300px") {
    float: left;
  }

  &:not(:last-child) {
    @include media(">=sm") {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  &:not(.pull-right) {
    .maxus {
      font-size: 10px;
      line-height: 15px;
      margin-left: 10px;
    }

    label {
      display: none;

      @include media(">=md") {
        display: inline-block;
      }
    }
  }

  &.pull-right {
    width: 100%;
    text-align: center;

    @include media(">=sm") {
      margin-top: 10px;
    }

    @include media(">=lg") {
      margin-top: 0;
    }

    @include media(">=1300px") {
      width: auto;
      text-align: left;
    }

    label {
      margin-right: 40px;
    }
  }
}

.maxus-filter-sorting__wrap {
  position: relative;
  display: block;

  @include media(">=sm") {
    display: inline-block;
  }

  .dropdown-menu {
    width: 100%;

    @include media(">=sm") {
      width: auto;
    }
  }
}

.maxus-filter-sorting__view {
  cursor: pointer;
  line-height: normal;
  transition: all .3s ease;
  color: $text-color;

  &:not(:last-child) {
    margin-right: 10px;
    display: inline-block;
  }

  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus,
  &.active {
    color: black;
  }
}

.maxus-filter-sorting__sidebar {
  padding: 0 10px;

  @include media(">=sm") {
    width: 26.5%;
    float: left;
  }

  .maxus-filter-sorting__item {
    padding-top: 15px;
    float: none;
    display: block;

    @include media(">=sm") {
      border-right: 1px solid #e1e1e1;
      padding: 50px 0 40px;
    }
  }
}

.maxus-filter-sorting__content {
  padding: 15px 10px;

  @include media(">=sm") {
    padding-top: 50px;
    padding-bottom: 40px;
    width: 73.5%;
    float: left;
  }

  .maxus-filter-sorting__item {
    @include media(">=sm") {
      display: inline-block;
      float: left;
      width: auto;
    }
  }

  .maxus-filter-sorting__item:not(.pull-right) {
    @include media(">=sm") {
      margin: 0 0 0 25px;
    }
  }

  .maxus-filter-sorting__item.pull-right {
    @include media(">=sm") {
      margin-top: 0;
      line-height: 33px;
    }
  }
}

.maxus-filter-collection .btn-maxus {
  text-transform: none;
  border-width: 1px;
  color: $text-color;
}

// FILTERS SELECTED
.maxus-filters-selected {
  padding: 30px 25px;

  label {
    line-height: 33px;
  }
}

.maxus-filters-selected__list {
  padding-left: 70px;
}

.maxus-filters-selected__item {
  display: inline-block;
  padding: 6px 12px;
  margin-right: 10px;
  background: #eef8f3;
  color: #000;
  border-radius: 5px;

  .fa {
    font-size: 10px;
    margin-left: 5px;
  }

  &:hover {
    background: $primary-color;
    color: white;
  }
}

.maxus-filters-selected__clear {
  color: $primary-color;

  &:hover {
    color: $brand-danger;
  }
}

// TEXT CAROUSEL
.maxus-text-carousel__wrap {
  margin: auto;
  color: #000;

  @include media("<md") {
    width: 100% !important;
  }
}

.maxus-text-carousel__item {
  @include media("<md") {
    font-size: 24px !important;
  }
}

// ACCOUNT
.maxus-account-form {
  @include media("<sm") {
    margin-bottom: 50px;
  }

  .text-center {
    text-align: center;

    @include media(">=sm") {
      text-align: left;
    }

    a {
      display: inline-block;
      margin-top: 20px;
    }
  }

  .btn-maxus {
    @include media(">=ss") {
      display: inline-block;
    }
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  textarea {
    box-shadow: none;
  }
}

.maxus-account-form__title {
  @include media("<lg") {
    font-size: 24px;
  }
}

// MY ACCOUNT
.maxus-myaccount {
  position: relative;
}

.maxus-myaccount__header {
  a {
    text-decoration: underline;

    @extend .twitter-text;

    &:hover {
      color: $brand-danger;
    }
  }
}

.maxus-myaccount__wrap {
  .table {
    border: 1px solid #ccc;
  }

  .table > thead > tr > th {
    border-width: 1px;
  }

  .table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
    padding: 15px 30px;
    vertical-align: middle;
  }

  a:hover:not(.btn-maxus) {
    color: $brand-danger;
  }
}

// SHOPPING CART
.maxus-shopping-cart {
  del {
    margin-right: 10px;
    color: $text-color;
  }

  ins {
    text-decoration: none;
  }

  .maxus-product__symbol,
  .woocommerce-Price-currencySymbol {
    font-size: 12px;
    vertical-align: text-top;
  }

  .table > tbody > tr > td:not(:last-child) {
    border-right: 1px solid #ccc;
  }

  .table > tfoot {
    background-color: #f8f8f8;
  }
}

.maxus-shopping-cart__item {
  white-space: normal;

  img {
    background-color: #ccc;
  }
}

.maxus-shopping-cart__image {
  text-align: center;

  @include media("<ss") {
    margin-right: 0 !important;
  }

  @include media(">=ss") {
    float: left;
    text-align: left;
  }
}

.maxus-shopping-cart__info {
  width: 235px;
  text-align: center;

  @include media(">=ss") {
    float: left;
    width: 300px;
    text-align: left;
  }
}

.maxus-shopping-cart__total {
  ins {
    text-decoration: none;
  }

  .maxus-product__symbol,
  .woocommerce-Price-currencySymbol {
    vertical-align: text-top;
  }
}

.maxus-shopping-cart__total.mr--90 {
  @include media("<lg") {
    margin-right: 0 !important;
    padding-right: 30px !important;
  }
}

.maxus-shopping-cart__total.pr-60 {
  @include media("<lg") {
    padding-right: 30px !important;
  }
}

.maxus-shopping-cart__total.text-right {
  @include media("<ss") {
    text-align: left;
  }
}

.maxus-shopping-cart__action {
  @include media("<xs") {
    .btn-maxus {
      display: block;
      margin-bottom: 20px;
    }
  }
}

.full-page {
  .maxus-shopping-cart {
    .maxus-myaccount__wrap .table {
      border: none;
    }

    .table > tbody > tr:first-child > td {
      border-top-color: transparent;
    }

    .table > tbody > tr > td {
      border-right: none;
    }
  }

  .maxus-shopping-cart__total.text-right {
    @include media("<sm") {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .maxus-shopping-cart__total.pr-60 {
    @include media("<sm") {
      padding-right: 0 !important;
    }
  }

  .maxus-shopping-cart__total.pl-60 {
    @include media("<sm") {
      padding-left: 0 !important;
    }
  }

  .maxus-shopping-cart__action {
    @include media("<sm") {
      .btn-maxus {
        display: block;
        margin-bottom: 20px;
      }
    }
  }
}

// CHECKOUT
.maxus-checkout {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  select,
  textarea {
    box-shadow: none;
    transition: all .3s ease;

    &:focus {
      box-shadow: none;
    }
  }

  .form-group label {
    display: block;
  }

  .form-group label span {
    display: block;
  }
}

.maxus-checkout__accordion:last-child {
  margin-bottom: 0 !important;
}

.maxus-checkout__title {
  position: relative;
  padding-left: 50px;
  line-height: 30px;
  cursor: pointer;

  &.in .maxus-checkout__icon {
    font-size: 8px;

    .maxus-arrow-angle-down:before {
      content: "\e904";
    }
  }
}

.maxus-checkout__icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #e1e1e1;
  color: $text-color;
  font-size: 5px;
  border-radius: 50%;

  @include absolute(0,null,null,0);
}

.maxus-checkout__content {
  @include media(">=ss") {
    padding-left: 50px;
  }
}

.maxus-checkout__total-info {
  font-size: 16px;

  .table > tbody > tr > td {
    padding: 15px 30px;
  }

  .table > tbody > tr:first-child > td {
    border-color: transparent;
  }
}

.maxus-checkout__login {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"] {
    border: none;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    padding-right: 0;

    &:focus {
      padding-left: 15px;
      padding-right: 15px;
      border-bottom-color: $primary-color;
    }
  }
}

.maxus-checkout__payment-info {
  background: #f1f1f1;
  padding: 15px;
  margin-top: 20px;
  color: #000;
}

// COMPARISION TABLE
.maxus-comparison-table {
  position: relative;
  border: 1px solid #e1e1e1;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  header {
    background-color: #f3f3f3;
    cursor: pointer;
    padding: 20px 30px;
    font-size: 20px;
  }
}

.maxus-comparison-table__content {
  min-height: 800px;
}

.maxus-comparison-table__column {
  display: none;
  width: 280px;
  border-right: 1px solid #e1e1e1;
  float: left;
  z-index: 1;

  @include absolute(0,null,0,0);

  @include media(">=md") {
    display: block;
  }

  .top-info {
    position: relative;
    padding: 30px 10px;

    ins {
      display: block;
      text-decoration: none;

      span {
        font-size: 9px;
        vertical-align: text-top;
      }
    }

    img {
      background-color: #f3f3f3;
    }

    .star-rating:before {
      color: #ff2947;

      @include fa("\f006\f006\f006\f006\f006");
    }
  }

  .list {
    padding: 0 25px;

    li {
      border-top: 1px dashed #e1e1e1;
      text-align: center;
      padding: 20px 10px;
    }
  }

  .none {
    display: inline-block;
    height: 2px;
    width: 30px;
    background-color: $brand-danger;
  }
}

.maxus-comparison-table__wrapper {
  position: relative;

  @include media(">=md") {
    padding-left: 280px;
  }

  .maxus-comparison-table__column {
    position: static;
    width: 20%;
  }
}

.maxus-comparison-table__navigation {
  z-index: 2;
  text-align: center;

  @include absolute(null,0,30px,0);

  li {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border: 2px solid #e1e1e1;
    border-radius: 50%;
    transition: all .3s ease;

    &:hover {
      border-color: #000;

      a {
        color: #000;
      }
    }
  }

  a {
    display: block !important;
    color: #e1e1e1;
  }
}

// IMAGES SHOW
.maxus-images-show {}

.maxus-images-show__img {
  position: relative;

  @include media(">=md") {
    display: inline-block;
    width: 66.6666667%;
  }
}

.maxus-images-show__content {
  position: relative;

  @include media(">=md") {
    display: inline-block;
    width: 33.3333333%;
    margin-left: -5px;
    vertical-align: middle;
  }
}

.maxus-images-show__info {
  padding: 50px 30px;

  @include media(">=xs") {
    padding: 80px;
  }

  @include media(">=1600px") {
    padding: 80px 270px 80px 100px;
  }
}

.maxus-images-show__title {
  font-style: italic;
}

// TECH TALK
.maxus-techtalk {
  position: relative;
  padding: 0;

  @include media(">=tn") {
    padding: 0 15px;
  }

  @include media(">=xs") {
    padding: 0 60px;
  }

  hgroup {
    @include media(">=xs") {
      margin: 0 -60px;
    }
  }
}

.maxus-techtalk__item {
  padding: 0 30px;

  & + & {
    margin-top: 50px;
  }
}

.maxus-techtalk__title {
  margin-bottom: 20px;
  line-height: 1.3;
}

// FULLPAGE
.maxus-full-page {
  position: relative;
  padding-left: 80px;
  height: 100vh;

  .maxus-slider {
    width: 100%;

    @include media(">=sm") {
      height: 100vh;
    }

    @include media(">=lg") {
      width: 50%;
      position: absolute;
    }

    img {
      width: 100%;

      @include media(">=sm") {
        height: 100vh;
      }
    }
  }

  .rev_slider,
  .tp-revslider-mainul,
  .tp-revslider-mainul * {
    height: 100vh !important;
  }

  .dione .tp-arr-imgholder {
    background-position: left center;
    background-size: 500%;
    background-repeat: no-repeat;
  }

  .dione.tparrows:before {
    font-size: 20px;
    margin-left: -10px;
  }

  .dione.tparrows.tp-leftarrow {
    transform: matrix(3, 0, 0, 3, 100, -475) !important;
  }

  .dione.tparrows.tp-rightarrow {
    transform: matrix(3, 0, 0, 3, -205, -475) !important;

    &:before {
      margin-left: 10px;
    }
  }

  .maxus-banner-image__item {
    height: 100%;
    width: 100%;

    @include media(">=sm") {
      display: block;
    }

    @include media(">=lg") {
      position: absolute;
      right: 0;
      width: 45.81%;
    }

    @include media(">=2560px") {
      width: 47%;
    }

    img {
      width: 100%;
    }

    .w-per-50 {
      @include media("<sm") {
        padding: 0;
      }
    }
  }

  @include media(">=sm") {
    .h-50vh img {
      height: 50vh;
    }

    .h-100vh img {
      height: 100vh;
    }
  }
}

// SEARCH
.maxus-search-full,
.maxus-categories {
  background-color: #fff;
  z-index: 50;
  display: none;

  @include fixed(0,0,0,0);
}

.maxus-search-full__info {
  transform: translateY(-50%);

  @include absolute(50%,0,null,0);

  input[type="text"],
  input[type="search"] {
    font-size: 100px;
    color: $primary-color;
    box-shadow: none;
    min-height: 0;
    height: auto;
    border: none;
    padding: 0;
    line-height: 0;
    font-weight: 600;

    &:focus {
      box-shadow: none;
    }

    @include placeholder(#f0f0f0);
  }
}

// CATEGORIES
.maxus-categories {
  .maxus-categories__footer {
    @include absolute(null,0,30px,0);
  }

  .navbar__label {
    font-size: 10px;
    font-weight: 300;
    width: 50px;
    height: 20px;
    line-height: 20px;
    top: -20px;
  }

  .navbar__sub-category {
    font-size: 18px;

    ul {
      box-shadow: none;
    }
  }

  .maxus-language {
    .dropdown-menu {
      left: 0;
      right: auto;
      border-bottom: 1px solid #e1e1e1;
      margin-bottom: 10px;
    }
  }
}

.maxus-categories--standard {
  .navbar__category {
    margin-top: 150px !important;
    font-size: 30px;

    @include media(">=1460px") {
      margin-top: 300px !important;
    }

    li:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.maxus-categories--megamenu {
  .navbar__category {
    height: 100vh;
    overflow: auto;
  }

  .maxus-categories__title {
    font-size: 24px;
    color: #fff;
    line-height: 1.1;
    margin-bottom: 25px;

    @include media(">=1460px") {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }

  .navbar__sub-category-megamenu {
    float: left;
    padding: 30px 30px 0 30px;
    width: 50%;

    @include media(">=1460px") {
      padding: 65px 65px 0 65px;
    }

    &:last-child {
      padding-bottom: 65px;
    }
  }

  .vw-50 {
    width: 50vw;
  }

  .vw-100 {
    width: 100vw;
  }

  .vh-45 {
    height: 45vh;
  }

  .vh-90 {
    height: 90vh;
  }

  .vh-100 {
    height: 100vh;
  }
}

.maxus-bxwishlist {
  .table > tbody > tr > td:not(:last-child) {
    border-right: 1px solid #ccc;
  }
}

.maxus-bxwishlist__img {
  a {
    display: block;
  }

  img {
    max-width: 120px;
    background-color: #e1e1e1;
  }
}

.maxus-bxwishlist__title {
  a {
    display: inline-block;
  }
}

.maxus-bxwishlist__control {
  font-size: 20px;
  line-height: 0;

  .maxus {
    margin-right: 10px;
  }

  .maxus-eye {
    font-size: 25px;
    vertical-align: middle;
  }
}

.maxus-bxwishlist__price {
  del {
    font-size: 12px;
    color: $text-color;
    margin-right: 10px;
  }

  ins {
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;

    .maxus-product__symbol,
    .woocommerce-Price-currencySymbol {
      font-size: 12px;
      vertical-align: text-top;
    }
  }
}

// QUICKVIEW
.white-popup {
  position: relative;
  background: #FFF;
  margin: auto;
  display: none !important;

  @include media(">=md") {
    display: block !important;
  }

  @include media(">=lg") {
    width: 960px;
  }

  @include media(">=1460px") {
    width: 1300px;
  }
}

.maxus-quickview {
  .mfp-close {
    color: #000;
    font-size: 24px;
    font-weight: 600;

    &:active,
    &:active:hover,
    &:active:focus {
      top: 0;
      background-color: transparent;
      color: #000;
      outline: none;
    }
  }

  .maxus-quickview__header {
    border-bottom-color: transparent;
  }

  .maxus-quickview__body {
    padding: 30px 15px;
  }

  .maxus-productdetail__preview {
    padding: 0;

    @include media(">=1460px") {
      padding: 0 60px;
    }
  }

  .maxus-productdetail__name {
    font-size: 28px;

    @include media(">=1460px") {
      font-size: 40px;
    }
  }
}

// SEARCH RESULTS
.search-results .site-main article {
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .tags-links {
    margin-right: 20px;
  }

  .comments-link {
    margin-right: 20px;

    &:before {
      margin-right: 5px;

      @include fa("\f040");
    }
  }

  .edit-link:before {
    margin-right: 5px;

    @include fa("\f044");
  }
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
