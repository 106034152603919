// ERROR 404
.maxus-404 {
  position: relative;
  color: #000;
  height: 100vh;
}

.maxus-404__wrap {
  display: table;
  min-width: 820px;

  @include center();
}

.maxus-404__title {
  display: table-cell;
  vertical-align: middle;
  font-size: 200px;
  line-height: .72;

  @include media(">=md") {
    width: 180px;
  }

  @include media(">=lg") {
    font-size: 250px;
    width: 260px;
  }

  @include media(">=1460px") {
    font-size: 300px;
  }
}

.maxus-404__info {
  display: table-cell;
  vertical-align: middle;
  font-size: 48px;
  line-height: 50px;
  width: 555px;

  @include media(">=1460px") {
    font-size: 60px;
    line-height: 70px;
  }

  .link-page {
    display: inline-block;
    line-height: 32px;

    &:after {
      content: "";
      display: block;
      height: 2px;
      width: 100%;
      background-color: #000;
      transition: all .3s ease;
    }

    &:hover:after {
      background-color: $primary-color;
    }
  }
}
