.product-categories__thumb {
  position: relative;
  z-index: 10;
  background-color: #f3f3f3;

  &:before {
    content: "";
    position: relative;
    padding-top: 35.41%;
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.product-categories__content {
  position: relative;
  background-color: #f3f3f3;

  > * {
    position: relative;
    z-index: 5;
  }

  .product-categories__bg,
  .product-categories__bg:after {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 100%;
    top: 0;
    z-index: 1;
    transition: transform 1s;
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  }

  .product-categories__bg {
    background: lighten(#01d5d8,5%);
  }

  .product-categories__bg:after {
    content: '';
    background: #01d5d8;
    transition: transform 0.7s;
  }
}

.product-categories__title {
  font-family: $font-3rd;
  padding: 30px;
  font-size: 32px;
  margin: 0;

  @include media(">=sm") {
    font-size: 50px;
    padding: 50px 90px;

  }

  > a:hover,
  > a:focus {
    color: $third-color;
  }

  @include media('>=sm') {
    font-size: 100px;
  }
}

.product-categories {
  overflow: hidden;
  position: relative;

  .product-categories__thumb > img,
  .product-categories__thumb:before {
    display: none;
  }

  &:hover .product-categories__content,
  &:focus .product-categories__content {
    background: none;

    .product-categories__bg,
    .product-categories__bg:after {
      transform: translate(100%, 0);
    }

    .product-categories__bg:after {
      transition-delay: 0.2s;
    }
  }

  @include media('>=sm') {
    .product-categories__thumb > img {
      width: 33.333334%;
      float: left;
      display: block;
    }

    .product-categories__thumb:before {
      display: block;
    }

    .product-caregories__main {
      position: absolute;
      top: 0;
      right: 0;
      bottom: -2px;
      width: 66.7%;
      overflow: hidden;
    }
  }
}


// CSS .product-categories--reverse
.product-categories--reverse {
  .product-categories__title {
    text-align: right;
  }

  @include media('>=sm') {
    .product-categories__thumb > img {
      float: right;
      left: auto;
      right: 0;
    }

    .product-caregories__main {
      left: 0;
      right: auto;
    }
  }

  .product-categories__bg,
  .product-categories__bg:after {
    left: 100%;
    right: auto;
  }

  &:hover,
  &:focus {
    .product-categories__content .product-categories__bg,
    .product-categories__content .product-categories__bg:after {
      transform: translate(-100%, 0);
    }
  }
}
