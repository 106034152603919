// BUTTON
.btn {
  line-height: 1.3;
  border-width: 2px;
  border-radius: 5px;
  transition: all .25s ease;
  font-family: $font-gotham-book;

  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    box-shadow: none;
    outline: none;
  }
}

.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.btn-outline.dropdown-toggle {
  &:hover,
  &:active,
  &:active:focus {
    border-color: rgba(0, 0, 0, 0.05) !important;
  }
}

.open > .btn-outline.dropdown-toggle {
  border-color: rgba(0, 0, 0, 0.05) !important;
}

.btn-default {
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    background: $brand-primary;
    border-color: $brand-primary;
  }
}

.btn-maxus {
  min-width: 170px;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1.5px;

  .fa {
    font-size: 15px;
    margin: 0 3px;
  }

  &.btn-rounded {
    border-radius: 21px !important;
  }

  &.btn-dropdown {
    padding-right: 42px;
  }

  &.dropdown-toggle {
    margin: 0;
    padding: 0;
    width: 42px;
    height: 42px;
    line-height: 42px;
    min-width: 0;
    text-align: center;
    z-index: 2;
    border-left: 2px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    @include absolute(0,0,0);
  }

  &:hover,
  &.btn-hover {
    &.dropdown-menu {
      border-color: #ccc;
    }

    &.btn-checkbox {
      span:before {
        background: #4be1ab;
      }
    }
  }

  &.btn-sharp {
    border-radius: 0 !important;
  }

  &.btn-circle {
    border-radius: 50% !important;
  }

  &.btn-prefix {
    padding: 0;
    text-align: left;

    .btn-prefix__item {
      border-right: 1px solid #e0e0e0;
    }

    span {
      display: inline-block;
      padding: 12px;
    }
  }
}

.btn-sm {
  padding: 8px 12px;
  border-radius: 3px;

  &.btn-rounded {
    border-radius: 17px !important;
  }

  &.btn-dropdown {
    padding-right: 34px;
  }

  &.dropdown-toggle {
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &.btn-prefix span {
    padding: 8px 12px;
  }
}

.btn-lg {
  min-width: 270px;
  font-size: 14px;
  letter-spacing: 0;

  &.btn-rounded {
    border-radius: 25px !important;
  }

  &.btn-dropdown {
    padding-right: 50px;
  }

  &.dropdown-toggle {
    width: 50px;
    height: 48px;
    line-height: 48px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &.dropdown-menu {
    padding: 5px 0;
    text-transform: none;
    font-size: 14px;
    letter-spacing: 0;
  }

  &.btn-prefix span {
    padding: 16px 12px;
  }
}

.btn-checkbox {
  position: relative;

  span:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    display: block;
    width: 22px;
    height: 22px;
    margin-right: 24px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transform: translateY(-50%);
  }

  span:after {
    display: none;
    position: absolute;
    top: 50%;
    left: 15px;
    color: white;
    transform: translateY(-50%);

    @include fa("\f00c");
  }

  input[type="checkbox"]:checked ~ span:after {
    display: block;
  }

  &.btn-outline {
    span:before {
      background: #4be1ab;
    }
  }
}

.btn-element {
  position: relative;
  min-width: 276px;
  text-align: left;
  padding-left: 70px;
  padding-right: 95px;

  input[type="text"],
  input[type="number"],
  input[type="search"] {
    width: 85px;
    height: 38px;
    min-width: 0;
    background: white;
    border: none;
    outline: none;
    margin: 0;
    color: black;
    padding: 0 25px 0 5px;
    box-shadow: none;
    appearance: none;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    @include absolute(0,0,0);
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    appearance: none;

    margin: 0;
  }

  .btn-rounded {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:after {
    color: #ccc;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);

    @include fa("\f175\f176");
  }

  &.btn-sharp {
    input[type="text"],
    input[type="number"],
    input[type="search"] {
      border-radius: 0 !important;
    }
  }
}

.btn-w160 {
  min-width: 160px !important;
}

.btn-shadow {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 1, 0.3);
  border: none !important;
  background-color: white;
  color: $third-color;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 15px 0px $primary-color;
    background-color: $primary-color;
  }
}

.btn-reset-style {
  background: none transparent;
  padding: 0;
  border: none;
  line-height: 0;
  font-weight: 300;
}
