// PRELOADING
.no-js #loading {
  display: none;
}

#loading {
  display: none\9; /* IE6, IE7, IE8, IE9 */
  margin-top: 0px;
  z-index: 999999;
  transition: all .8s ease;

  @include fixed(0,0,0,0);

  &:before,
  &:after {
    content: "";
    width: 100%;
    height: 50vh;
    position: absolute;
    z-index: -1;
    transition: all 2s ease;
  }

  &:before {
    top: 0;
    background-color: $primary-color;
  }

  &:after {
    bottom: 0;
    background-color: $brand-warning;
  }
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

.loading-done {
  @include vhidden();
}

.loading-process {
  &:before {
    top: -100vh !important;
  }

  &:after {
    bottom: -100vh !important;
  }
}

#loading-center-absolute {
  height: 200px;
  width: 200px;
  margin-top: -100px;
  margin-left: -100px;
  transform: rotate(-135deg);

  @include absolute(50%,null,null,50%);
}

.object {
  position: absolute;

  border-top: 5px solid #FFF;
  border-bottom: 5px solid transparent;
  border-left:  5px solid #FFF;
  border-right: 5px solid transparent;

  border-radius: 50%;

  animation: animate 2s infinite;
}

#object_one {
  left: 75px;
  top: 75px;
  width: 50px;
  height: 50px;
}

#object_two {
  left: 65px;
  top: 65px;
  width: 70px;
  height: 70px;
  animation-delay: 0.2s;
}

#object_three {
  left: 55px;
  top: 55px;
  width: 90px;
  height: 90px;
  animation-delay: 0.4s;
}

#object_four {
  left: 45px;
  top: 45px;
  width: 110px;
  height: 110px;
  animation-delay: 0.6s;
}

@-webkit-keyframes animate {
  50% {
    transform: rotate(360deg) scale(0.8);
  }
}

@keyframes animate {
  50% {
    transform: rotate(360deg) scale(0.8);
  }
}
