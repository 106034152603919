// DISCOUNT
.maxus-discount {
  background: #f3f3f3;
  text-align: center;

  @include media(">=md") {
    text-align: left;
  }

  .btn-maxus {
    margin: 32px 0;
  }

  @include media("<md") {
    .text-right {
      text-align: center;
    }
  }
}

.maxus-discount__info,
.maxus-discount__meta {
  display: inline-block;
  margin-bottom: 0;
}

.maxus-discount__info {
  padding: 20px 0;

  @include media(">=md") {
    padding-top: 30px;
    padding-bottom: 0;
  }

  @include media(">=lg") {
    padding: 40px 0;
  }
}

.maxus-discount__modal {
  .modal-sm {
    width: 300px;
    margin: 0;
  }

  .close {
    color: #333;
    font-size: 24px;
    font-weight: 600;

    &:hover,
    &:active,
    &:active:hover,
    &:focus {
      color: #000;
      background: none;
      outline: none;
    }
  }

  .modal-dialog {
    @include center();
  }

  &.modal.fade .modal-dialog {
    transform: translate(-50%,-50%);
  }

  &.modal.in .modal-dialog {
    transform: translate(-50%,-50%);
  }
}

.maxus-discount__form {
  position: relative;

  input[type="text"],
  input[type="number"] {
    border-radius: 5px 20px 20px 5px;
    padding-right: 50px;
    box-shadow: none;
  }

  .btn-maxus {
    min-width: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    margin-top: -20px;

    @include absolute(50%,0);
  }
}
