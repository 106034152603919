.product-litter {
  overflow: hidden;

  .maxus-product__name {
    font-size: 30px;
    letter-spacing: 0;
  }

  .maxus-product__price ins {
    text-decoration: none;
  }

  .maxus-product__amount {
    font-size: 20px;
    color: $third-color;

    .maxus-product__symbol,
    .woocommerce-Price-currencySymbol {
      font-size: 15px;
      display: inline-block;
      vertical-align: top;
      margin-top: 6px;
    }
  }

  .product-litter__main {
    padding: 50px 30px;
  }

  @include media('>=xs') {
    .product-litter__main {
      padding: 50px;
    }
  }

  @include media('>=lg') {
    .product-litter__media,
    .product-litter__main {
      float: left;
      width: 50%;
    }
  }

  @include media('>=1460px') {

    .product-litter__media {
      width: 66.66667%;
    }

    .product-litter__main {
      width: 33.33333%;
      padding: 100px 50px 100px 80px;
    }

    .maxus-product__name {
      font-size: 48px;
    }

    .maxus-product__amount {
      font-size: 30px;
    }
  }
}

// CSS .maxus-bg-hot
.maxus-bg-hot {
  background-image: url('../img-demo/products/bg-hot.jpg');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: gray
}

// CSS .product-litter--modern
.product-litter--modern {
  position: relative;
  background-color: #f3f3f3;
  height: 100%;

  .maxus-product__image.maxus-bg-hot {
    padding: 70px;
  }

  .product-litter__media {
    width: 100% !important;
    float: none;
  }

  @include media('>=sm') {
    .maxus-product__image.maxus-bg-hot {
      text-align: right;
      padding: 130px;
    }

    .maxus-product__image img {
      width: 50%;
    }

    .product-litter__main {
      width: 50%;
      padding-left: 20%;
      padding-right: 0;

      @include absolute(0,null,0,0);
      z-index: 10;
    }
  }
}

// CSS .product-litter--background
.product-litter--background {
  position: relative;

  .product-litter__media {
    width: 100% !important;
    float: none;
  }

  .maxus-product__image {
    position: relative;
    background-color: gray;

    &:after {
      content: "";
      display: block;
      padding-top: 40.3%;
      position: relative;
    }

    img {
      @include absolute(0,null, null, 0);
    }
  }

  @include media('>=md') {
    .product-litter__main {
      padding: 0;

      @include absolute(0,0,0,0);
      z-index: 10;
    }
  }

  @include media('>=md') {
    .product-litter__main {
      width: 960px;
      float: none;
      margin-right: auto;
      margin-left: auto;
      padding-left: 10px;
      padding-right: 10px;
    }

    .product-litter__content {
      width: 360px;
      float: right;
      margin-right: 40px;
    }
  }

  @include media('>=1300px') {
    .product-litter__main {
      width: 1300px;
    }

    .product-litter__content {
      width: 400px;
      margin-right: 80px;
    }
  }
}

