// BRAND
.maxus-brand {
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.maxus-brand--border {
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.maxus-brand__item {
  float: left;
  text-align: center;
  position: relative;
  border-right: 1px solid #e1e1e1;
}

.maxus-brand__wrap {
  text-align: center;
  display: table;
  width: 100%;
  height: 130px;

  a {
    display: table-cell;
    vertical-align: middle;
    font-size: 0;
    outline: none;
  }

  img {
    display: inline-block;
    opacity: .5;
    filter : grayscale(100%);
    transition: all .3s ease;
  }

  &:hover img {
    opacity: 1;
    filter : grayscale(0);
  }
}

