// ../vendor/bootstrap/mixins/_buttons.scss
@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &.btn-outline {
    color: black;
    background-color: transparent;
    border-color: #e0e0e0;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:active:focus,
    &.btn-hover {
      color: $background;
      background-color: transparent;
      border-color: $border;
    }

    &:active,
    &.active,
    .open > &.dropdown-toggle {
      color: $background;
      background-color: transparent;
      border-color: $border;

      &:hover,
      &:focus,
      &.focus {
        color: $background;
        background-color: transparent;
        border-color: $border;
      }
    }

    &.btn-hover:hover {
      color: lighten($background, 5%);
      border-color: lighten($border, 5%);
    }
  }

  &:focus,
  &.focus {
    color: $color;
    background-color: lighten($background, 10%);
        border-color: lighten($border, 25%);
  }
  &:hover {
    color: $color;
    background-color: lighten($background, 10%);
        border-color: lighten($border, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: lighten($background, 10%);
        border-color: lighten($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: lighten($background, 17%);
          border-color: lighten($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
          border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}
