// INSTAGRAM
.maxus-instagram {
  position: relative;

  .row {
    margin: 0 -10px !important;
  }
}

.maxus-instagram__header {
  padding: 30px 0 0;
  margin-bottom: 55px;
}

.maxus-instagram__title {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;

  @include media(">=sm") {
    font-size: 24px;
    margin-bottom: 0;
    text-align: left;
  }
}

.maxus-instagram__textlink {
  font-size: 18px;
  text-align: center;

  @include media(">=sm") {
    text-align: right;
  }

  .fa {
    font-size: 22px;
    vertical-align: middle;
    color: #ccc;
  }

  &:hover {
    .fa {
      color: $primary-color;
    }
  }
}

.maxus-instagram__arrow {
  position: relative;
  display: inline-block;
  border: 1px solid #e1e1e1;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  border-radius: 50%;

  &:after {
    font-size: 12px;

    @include fa("\f105");
    @include center();
  }
}

.maxus-instagram__container {
  position: relative;
}

.maxus-instagram__text-overlay {
  display: none;

  @include absolute(0,0,0,0);

  @include media(">=md") {
    display: block;
  }

  &:before {
    content: "";

    @include overlay(black,.5,1);
  }

  a {
    display: block;
    color: white;
    font-size: 30px;
    z-index: 2;

    @include center();

    &:hover {
      color: $primary-color;
    }
  }
}

.maxus-instagram__item {
  float: left;
  overflow: hidden;
  width: 100%;

  @include media(">=ss") {
    width: 50%;
  }

  @include media(">=md") {
    width: 33.333333%;
  }

  @include media(">=lg") {
    width: 16.666667%;
  }

  a {
    position: relative;
    display: block;
    font-size: 0;

    &:before,
    &:after {
      content: "";

      @include vhidden();
    }
  }

  a:before {
    border: 5px solid rgba(0,0,0,.5);
    transition: all .3s ease;

    @include absolute(0,0,0,0);
  }

  a:after {
    background-color: rgba(0,0,0,.5);
    transition: all .8s ease;

    @include absolute(30px,30px,30px,30px);
  }

  img {
    width: 100%;
  }

  &:hover {
    a:before,
    a:after {
      @include vdisplay();
    }
  }
}
