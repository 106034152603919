// BANNER
.maxus-banner {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;

  @include media(">=sm") {
    text-align: left;
  }

  img {
    position: relative;
    z-index: 2;

    @include media(">=sm") {
      float: right;
    }

    @include media(">=md") {
      margin-right: 260px;
    }
  }

  .btn-maxus {
    z-index: 2;
    transform: translateX(-50%);

    @include absolute(null,null,15px,50%);

    @include media(">=sm") {
      transform: translateY(-50%);
      top: 50%;
      right: 57px;
      left: auto;
      bottom: auto;
    }

    &:hover {
      background-color: $primary-color;
      color: white;
      border-color: $primary-color;
    }
  }
}

.maxus-banner__info {
  z-index: 2;
  color: white;
  position: relative;
  padding: 10px 10px 70px;

  @include media(">=sm") {
    padding: 0;
    transform: translateY(-50%);

    @include absolute(50%,null,null,60px);
  }
}

.maxus-banner__title {
  font-family: $font-3rd;
  font-size: 36px;
  font-weight: 600;
}

.maxus-banner-image {
  position: relative;
}

.maxus-banner__item {
  text-align: center;
}

.maxus-banner__item--mt {
  margin-top: 20px !important;

  @include media(">=sm") {
    margin-top: 0 !important;
  }
}

.maxus-banner__item--mb {
  margin-bottom: 20px !important;

  @include media(">=sm") {
    margin-bottom: 0 !important;
  }
}
