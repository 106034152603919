.sticky {
  display: block;
}

.byline,
.updated:not(.published){
  display: none;
}

.single .byline,
.group-blog .byline {
  display: inline;
}

.page-links {
  clear: both;
  margin: 0 0 1.5em;
}
