html, body {
  font-weight: 300;
}

body.admin-bar {
  .navbar-mobile.affix {
    @include media(">=600px") {
      margin-top: 46px;
    }
  }
}

a {
  transition: all .3s ease;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
}

.tp-caption.boxshadow, .boxshadow {
  box-shadow: none !important;
}

.tb {
  display: table;
  width: 100%;
  height: 100%;
}

.tb-cell,
.table-cell-center {
  display: table-cell;
  vertical-align: middle;
}

.table-cell-center {
  width: 1%;
  text-align: center;
}

.list-style-none {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dark {
  color: $third-color;
}

.white {
  color: white;

  &:hover {
    color: $primary-color;
  }
}

.font-italic {
  font-style: italic;
}

.dropdown-menu > li > a {
  font-weight: 300;
}

.says {
  display: none;
}

.maxus-overlay {
  z-index: 1;
  transition: all .3s ease;

  @include absolute(0,0,0,0);
}

.slick-initialized .slick-slide {
  outline: none;
}

[data-color-class="primary"] {
  background-color: rgba($brand-primary, .8) !important;
}

[data-color-class="danger"] {
  background-color: rgba($brand-danger, .8) !important;
}

[data-color-class="twitter"] {
  background-color: rgba(#00b6d6, .8) !important;
}

[data-color-class="blue"] {
  background-color: rgba(#3b55e6, .8) !important;
}

[data-color-class="info"] {
  background-color: rgba($brand-info, .8) !important;
}

[data-color-class="warning"] {
  background-color: rgba($brand-warning, .8) !important;
}

[data-color-class="white"] {
  background-color: rgba(white, .8) !important;
}

[data-color-class="gray"] {
  background-color: rgba(#f6f6f6, .8) !important;
}

.main-layout .widget-area {
  width: 100%;

  @include media(">=md") {
    width: 25%;
  }
}

.main-layout.sidebar-left .widget-area {
  right: 0%;

  @include media(">=md") {
    right: 75%;
  }
}

.main-layout .content-area {
  width: 100%;

  @include media(">=md") {
    width: 75%;
  }

  .pagination-button {
    min-width: 0;
  }

  .pagination > li:first-child {
    left: -230px;
  }

  .pagination > li:last-child {
    right: -230px;
  }
}

.main-layout.sidebar-left .content-area {
  left: 0%;

  @include media(">=md") {
    left: 25%;
  }
}

.tp-tabs, .tp-thumbs, .tp-bullets {
  z-index: 99 !important;
}

.margin-center {
  margin: auto;
}

.text-middle {
  vertical-align: middle;
}

.no-gutter {
  padding: 0 !important;

  .maxus-product__item {
    margin-bottom: 0 !important;
  }
}

.w-per-50 {
  width: 100%;
  padding: 10px;

  @include media(">=sm") {
    width: 50%;
    padding: 0;
  }
}

.w-per-96 {
  width: 100% !important;
  padding: 10px;

  @include media(">=sm") {
    width: 94.3% !important;
    padding: 0;
  }

  @include media(">=1460px") {
    width: 96% !important;
  }
}

@each $pt in 20, 30, 50, 65, 80, 90, 130, 185  {
  .pt-#{$pt} {
    padding-top: #{$pt}px !important;
  }
}

@each $pb in 30, 40, 65, 80, 90, 130, 185  {
  .pb-#{$pb} {
    padding-bottom: #{$pb}px !important;
  }
}

@each $pl in 0, 20, 30, 40, 60, 80, 100  {
  .pl-#{$pl} {
    padding-left: #{$pl}px !important;
  }
}

@each $pr in 0, 20, 30, 40, 60, 100 {
  .pr-#{$pr} {
    padding-right: #{$pr}px !important;
  }
}

@each $ml in 0, 20, 25 {
  .ml-#{$ml} {
    margin-left: #{$ml}px !important;
  }
}

@each $mr in -90, -40, -20, 0, 10, 20, 25, 30, 50 {
  .mr-#{$mr} {
    margin-right: #{$mr}px !important;
  }
}

@each $mt in -120, -65, -15, 0 {
  .mt-#{$mt} {
    margin-top: #{$mt}px !important;
  }
}

@each $mb in 0, 5, 10, 15, 20, 25, 30, 45, 50, 55, 60, 65, 70, 75, 100 {
  .mb-#{$mb} {
    margin-bottom: #{$mb}px !important;
  }
}

@each $fz in 7, 10, 12, 14, 16, 18, 20, 21, 24, 30, 36, 40, 42, 48, 60, 62, 72 {
  .fz-#{$fz} {
    @if $fz >= 30 {
      font-size: #{$fz/2}px !important;
    }
    @else {
      font-size: #{$fz}px !important;
    }

    @include media(">=sm") {
      font-size: #{$fz}px !important;
    }
  }
}

@each $w in 95, 160, 180, 300, 420, 520, 620, 640, 780, 940 {
  .w-#{$w} {
    width: 100%;
    min-width: 0;

    @include media(">=ss") {
      width: #{$w}px !important;
      min-width: #{$w}px !important;
    }
  }
}

@for $i from 2 through 12 {
  [data-cols="#{$i}"] {
    width: 100%;

    @include media(">=ss") {
      width: 50%;
    }

    @include media(">=md") {
      width: percentage($i / 12);
    }
  }
}

@each $name in top, right, bottom, left {
  .border-#{$name} {
    border-#{$name}: 1px solid #e1e1e1 !important;
  }
}

@each $name, $weight in
(300, 300),
(400, 400),
(500, 500),
(600, 600),
(700, 700) {
  .font-#{$name} {
    font-weight: $weight !important;
  }
}

@each $name, $family in
(font-base, $font-gotham),
(font-2nd, $font-2nd),
(font-3rd, $font-3rd) {
  .#{$name} {
    font-family: $family !important;
  }
}

@each $name, $color in
(primary,       #00d967),
(secondary,     #959595),
(third,         #101010),
(info,          #3e3576),
(warning,       #eadc00),
(danger,        #ff2947),
(yellow,        #ecb330),
(blue,          #3b55e6),
(pink,          #e8b8c4),
(bluewhite,     #8ea6e6),
(blueblack,     #87b2ca),
(facebook,      #3e3576),
(twitter,       #00b6d6),
(pinterest,     #ff2947),
(instagram,     #00d967),
(google-plus,   #E02F2F),
(linkedin,      #0083BE),
(digg,          #44C4A1),
(tumblr,        #415B6F),
(reddit,        #F05825),
(stumbleupon,   #EB4924),
(darkseagreen,  #5a7d62),
(orangered,     #f74d62),
(purpleblack,   #3a405b),
(saddlebrown,   #874017),
(gray,          #cccccc),
(black,         #000000),
(white,         #ffffff),
(whiteblack,    #f1f1f1),
(watch-1,       #c6a88d),
(watch-2,       #e2e0d4),
(watch-3,       #8ea6e6),
(watch-4,       #3c4973) {
  .#{$name}-text {
    color: $color;
  }

  .#{$name}-color {
    @if $name == white {
      background-color: $color;
      color: black;
      border-color: $color;

      &.btn-outline {
        color: black;
        background-color: transparent;
        border-color: black;

        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active,
        &:active:focus,
        &.btn-hover {
          color: black;
          background-color: $primary-color;
          border-color: $primary-color;
        }

        &:active,
        &.active,
        .open > &.dropdown-toggle {
          color: black;
          background-color: $primary-color;
          border-color: $primary-color;

          &:hover,
          &:focus,
          &.focus {
            color: black;
            background-color: $primary-color;
            border-color: $primary-color;
          }
        }

        &.btn-hover:hover {
          color: black;
          border-color: $primary-color;
        }
      }

      &:focus,
      &.focus {
        color: $color;
        background-color: $primary-color;
            border-color: $primary-color;
      }
      &:hover {
        color: $color;
        background-color: $primary-color;
            border-color: $primary-color;
      }
      &:active,
      &.active,
      .open > &.dropdown-toggle {
        color: $color;
        background-color: $primary-color;
            border-color: $primary-color;

        &:hover,
        &:focus,
        &.focus {
          color: $color;
          background-color: $primary-color;
              border-color: $primary-color;
        }
      }
      &:active,
      &.active,
      .open > &.dropdown-toggle {
        background-image: none;
      }
      &.disabled,
      &[disabled],
      fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
          background-color: $primary-color;
              border-color: $primary-color;
        }
      }

      .badge {
        color: $color;
        background-color: $primary-color;
      }
    } @else {
      @include button-variant(white, $color, $color);
    }
  }

  .#{$name}-size-color {
    input:checked ~ span {
      @if $name == white {
        background: darken($color, 2%) !important;
        color: black !important;
      } @else {
        background: $color !important;
        color: white !important;
      }
    }
  }

  .#{$name}-maxus-color {
    span {
      @if $name == white {
        background: darken($color, 2%) !important;
        &:before {
          color: black !important;
        }
      } @else {
        background: $color !important;
        &:before {
          color: white !important;
        }
      }
    }
  }

  .#{$name}-share-color {
    background-color: $color;
    color: white !important;
  }

  .#{$name}-select-color,
  .#{$name}-bg-color {
    background-color: $color !important;
  }

  .pager-blog-#{$name}-color {
    background-color: rgba($color, .9) !important;
  }

  .feature-#{$name}-color {
    .maxus-overlay {
      background-color: $color;
    }

    .maxus-feature__info {
      border-top-color: darken($color, 10%);
    }
  }

  .banner-#{$name}-color {
    background-color: $color;
  }

  .tab-controls-#{$name}-color {
    li > a:hover,
    li > a:focus,
    li > a:active,
    li > a:active:focus,
    li.active > a {
      background-color: $color;
      border-color: $color !important;
      color: white;
    }

    .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
      background-color: $color;
    }
  }

  .#{$name}-product-color {
    .maxus-product__info {
      &:before,
      &:after {
        background-color: $color;
      }
    }

    &[data-style="background"].hover,
    &[data-style="background"]:hover {
      background-color: $color;

      a, .maxus-product__price, ins {
        color: white;
      }
    }

    &.maxus-product__item--list:hover .maxus-product__image,
    &.maxus-product__item--slider:hover .maxus-product__image {
      background-color: $color;
    }
  }

  .product:nth-child(2n+2) .#{$name}-product-color.maxus-product__item--list:hover .maxus-product__image {
    background-color: $color;
  }
}

// Small extra grid
@include media('>=xs', '<sm') {
  @include make-grid(ss);
}

// Tiny grid
@include media('>=tn', '<xs') {
  @include make-grid(tn);
}
