@import "font-face";

//== Breakpoints
$breakpoints: (tn: 425px, xs: 480px, ss: 520px, sm: 768px, md: 992px, lg: 1200px);


//== Colors
$primary-color:                                   #00d967;
$secondary-color:                                 #959595;
$third-color:                                     #101010;

$brand-primary:                                   $primary-color;
$brand-success:                                   #00d967;
$brand-info:                                      #3e3576;
$brand-warning:                                   #eadc00;
$brand-danger:                                    #ff2947;


//== Scaffolding
$body-bg:                                         #fff;
$maxus-bg:                                        #ebebeb;
$maxus-bg-second:                                 #f9f9f9;

$text-color:                                      $secondary-color;
$link-color:                                      $third-color;
$link-hover-color:                                $brand-primary;
$link-hover-decoration:                           none;


//== Typography
$font-playfair:                                   "Playfair Display", serif;
$font-gotham:                                     "Gotham", serif;
$font-gotham-book:                                "Gotham-Book", serif;

$font-family-base:                                $font-gotham;
$font-2nd:                                        $font-gotham-book;
$font-3rd:                                        $font-playfair;

$font-size-base:                                  14px;
$font-size-large:                                 16px;
$font-size-small:                                 11px;

$font-size-h1:                                    30px;
$font-size-h2:                                    20px;
$font-size-h3:                                    18px;
$font-size-h4:                                    16px;
$font-size-h5:                                    $font-size-base;
$font-size-h6:                                    12px;

$line-height-base:                                1.5;

$headings-font-family:                            $font-gotham;
$headings-font-weight:                            600;
$headings-line-height:                            1.1;
$headings-color:                                  $third-color;

//== Components
$padding-base-vertical:                           12px;
$padding-base-horizontal:                         12px;

$padding-large-vertical:                          13px;
$padding-large-horizontal:                        16px;

$padding-small-vertical:                          8px;
$padding-small-horizontal:                        10px;

$padding-xs-vertical:                             1px;
$padding-xs-horizontal:                           5px;

$line-height-large:                               1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small:                               1.5;

$border-radius-small:                             0;
$border-radius-base:                              0;
$border-radius-large:                             0;

$border-solid-color:                              #e1e1e1;
$border-radius-color:                             #ebebeb;


//== Buttons
$btn-font-weight:                                 700;

$btn-default-color:                               white;
$btn-default-bg:                                  #111111;
$btn-default-border:                              #111111;

$btn-primary-color:                               #fff;
$btn-primary-bg:                                  $brand-primary;
$btn-primary-border:                              $brand-primary;

$btn-success-color:                               #fff;
$btn-success-bg:                                  $brand-primary;
$btn-success-border:                              $brand-primary;

$btn-info-color:                                  #fff;
$btn-info-bg:                                     $brand-info;
$btn-info-border:                                 $brand-info;

$btn-warning-color:                               #fff;
$btn-warning-bg:                                  $brand-warning;
$btn-warning-border:                              $brand-warning;

$btn-danger-color:                                #fff;
$btn-danger-bg:                                   $brand-danger;
$btn-danger-border:                               $brand-danger;

$btn-link-disabled-color:                         $secondary-color;

$btn-border-radius-base:                          5px;
$btn-border-radius-small:                         3px;
$btn-border-radius-large:                         5px;


//== Forms
$input-border-focus:                              $brand-primary;
$input-color-placeholder:                         $text-color;
$input-height-base:                               40px;
$form-group-margin-bottom:                        20px;


//== Grid system
$grid-columns:                                    12;
$grid-gutter-width:                               20px;


// Large screen / wide desktop
$screen-lg:                                       1300px;
$container-large-desktop:                         (1280px + $grid-gutter-width);


//== Small screen / tablet
$grid-float-breakpoint:                           992px;


//== Pagination
$pagination-color:                                $secondary-color;
$pagination-bg:                                   white;
$pagination-border:                               transparent;

$pagination-hover-color:                          $link-hover-color !default;
$pagination-hover-bg:                             transparent;
$pagination-hover-border:                         transparent;

$pagination-active-color:                         $brand-primary;
$pagination-active-bg:                            white;
$pagination-active-border:                        transparent;

$pagination-disabled-border:                      transparent;


//== Form states and alerts
$state-success-text:                              $brand-success;
$state-success-bg:                                $brand-success;
$state-success-border:                            $brand-success;

$state-info-text:                                 $brand-info;
$state-info-bg:                                   $brand-info;
$state-info-border:                               $brand-info;

$state-warning-text:                              $brand-warning;
$state-warning-bg:                                $brand-warning;
$state-warning-border:                            $brand-warning;

$state-danger-text:                               $brand-danger;
$state-danger-bg:                                 $brand-danger;
$state-danger-border:                             $brand-danger;


//== Alerts
$alert-padding:                                   18px;
$alert-border-radius:                             5px;
$alert-link-font-weight:                          normal;

$alert-success-bg:                                $state-success-bg;
$alert-success-text:                              white;
$alert-success-border:                            $state-success-border;

$alert-info-bg:                                   $state-info-bg;
$alert-info-text:                                 white;
$alert-info-border:                               $state-info-border;

$alert-warning-bg:                                $state-warning-bg;
$alert-warning-text:                              white;
$alert-warning-border:                            $state-warning-border;

$alert-danger-bg:                                 $state-danger-bg;
$alert-danger-text:                               white;
$alert-danger-border:                             $state-danger-border;

//== Breadcrumbs
$breadcrumb-bg:                                   transparent;
$breadcrumb-color:                                #000;

$breadcrumb-active-color:                         #ff2947;


//== Close
$close-color:                                     white;
$close-text-shadow:                               none;


//== Type
$page-header-border-color:                        $border-solid-color;
