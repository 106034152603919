/* Widgets as list  */
.widget + .widget {
  border-top: 1px solid #e1e1e1;
  margin-top: 20px;
}

.widget {
  padding-top: 30px;

  &:first-child {
    margin-top: 20px;
  }

  &:last-child {
    @include media(">=md") {
      margin-bottom: 100px;
    }
  }

  ul, ol {
    @extend .list-unstyled;
    margin-bottom: 0;
    li {
      position: relative;
      > a {
        display: inline-block;
        padding-right: 5px;
      }
      &:not(:last-child) {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #e1e1e1;
      }
      > a:hover {
        text-decoration: none;
      }
    }
    ul, ol {
      margin-top: 12px;
      padding-top: 12px;
      border-top: 1px solid #e1e1e1;
      margin-bottom: 0;
      padding-left: 20px;
    }
    li.current-cat > a {
      color: $primary-color;
    }
  }

  > ul, > div {
    display: none;

    @include media(">=md") {
      height: auto !important;
      display: block !important;
    }
  }

  input[type="search"],
  input[type="text"],
  input[type="email"] {
    font-weight: 300;
    border: 1px solid #e0e0e0;
    color: black;
    font-size: 12px;
    padding: 25px 50px 25px 25px !important;
    width: 100%;
    border-radius: 0;

    @include placeholder(#717171);
  }

  select {
    height: auto;
    box-shadow: none;
    appearance: menulist;
  }

  .chosen {
    a {
      position: relative;
      padding-left: 10px;
    }

    a:before {
      font-size: 10px;
      transform: translateY(-50%);

      @include fa("\f00d");
      @include absolute(50%,null,null,0);
    }
  }

  a.remove {
    display: block;
    font-size: 1.5em;
    height: 1em;
    width: 1em;
    text-align: center;
    line-height: 1;
    border-radius: 100%;
    color: red!important;
    text-decoration: none;
    font-weight: 700;
    border: 0;
  }
}

.widget-title {
  position: relative;
  text-transform: uppercase;
  font-size: $font-size-base;
  margin-bottom: 20px;
  padding: 5px 0;
  cursor: pointer;

  &:after {
    color: #b8b8b8;
    transform: translateY(-50%);

    @include absolute(50%,0);
    @include famaxus("\e92a");

    @include media(">=md") {
      display: none;
      content: "\e925";
    }
  }

  &.collapsed:after,
  &.in:after {
    content: "\e925";
  }

  @include media(">=md") {
    &.in:after {
      display: none;
      content: "\e925";
    }
  }
}

.widget-form {
  position: relative;
}

.widget-inlineblock > * {
  display: inline-block;
  min-width: 120px;
}

/* Widget nav-menu fixed */
.widget_nav_menu .menu {
  ul {
    opacity: 1;
    visibility: visible;
    position: static;
    display: block;
    padding-left: 0;
    li {
      padding-left: 15px;
    }
  }
}

/* Widget calendar */
.widget_calendar table {
  margin: 0;
  width: 100%;
  position: relative;
  td,
  th {
    padding: 0;
    line-height: 3;
    text-align: center;
  }
  caption {
    color: $third-color;
    padding-top: 0;
    padding-bottom: 12px;
    margin-bottom: 5px;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid #e1e1e1;
  }
  thead tr th {
    color: $third-color;
    font-weight: 400;
  }
  tbody tr td {
    font-size: 12px;
  }
  tbody a {
    color: black;
    font-weight: 700;
    display: block;
    background-color: #f0f0f0;
    &:hover,
    &:focus {
      background-color: darken(#f0f0f0, 10%);
    }
  }
  tfoot {
    height: 0;
    display: inline-block;
    overflow: hidden;
  }
  tfoot a {
    top: 7px;
    position: absolute;
    line-height: 1;
  }
  #prev a {
    left: 0;
  }
  #next a {
    right: 0;
  }

  #today,
  #today a {
    color: white;
    background-color: $primary-color;
    &:hover,
    &:focus {
      color: white;
      background-color: darken($primary-color, 20%);
    }
  }
}

/* Widget tagcloud */
.widget_tag_cloud a,
.widget_product_tag_cloud a {
  display: inline-block;
  padding: 10px 16px;
  margin: 0 5px 10px 0;
  border: 1px solid #e0e0e0;
  color: #717171;
  font-size: 14px !important;
  transition: all .4s ease;

  &:hover {
    background: $primary-color;
    color: white;
    border-color: $primary-color;
  }
}

/* Widget product categories */
.widget_product_categories {
  .cat-item {
    position: relative;
    font-size: 12px;
    line-height: 2;
    padding-left: 23px;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 14px;
      height: 14px;
      background-color: #efefef;
      border-radius: 5px;

      @include absolute(5px,null,null,0);
    }

    a {
      color: #000;

      &:hover {
        color: $primary-color;
      }
    }

    &.current-cat {
      &:before {
        background-color: $primary-color;
      }

      &:after {
        color: white;
        font-size: 10px;

        @include absolute(6px,null,null,2px);
        @include fa("\f00c");
      }

      a {
        color: $primary-color;
      }
    }
  }
}

/* Widget price filter */
.widget_price_filter {
  input[type="text"] {
    display: none;
  }

  button {
    margin-top: 45px;
    width: 180px;

    @extend .btn;
    @extend .btn-maxus;
    @extend .btn-default;
    @extend .btn-sharp;
  }

  .price_slider_amount {
    position: relative;
    margin-top: 3px;
  }

  .price_label {
    font-size: 0;
    width: 180px;

    @include absolute(10px,0,null,0);

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    span {
      font-size: 12px;
      color: #000;
    }

    .from {
      float: left;
    }

    .to {
      float: right;
    }
  }

  .price_slider {
    position: relative;
    width: 180px;
    height: 5px;
    background-color: #efefef;
    border-radius: 5px;

    .ui-slider-range {
      position: absolute;
      height: 5px;
      background-color: $primary-color;
    }

    .ui-slider-handle {
      position: absolute;
      top: -3px;
      display: inline-block;
      width: 11px;
      height: 11px;
      margin-left: -5px;
      border: 3px solid $primary-color;
      background-color: white;
      border-radius: 50%;
      overflow: hidden;
      outline: none;
      cursor: pointer;
    }
  }
}

/* Widget rating filter */
.widget_rating_filter {
  .star-rating {
    float: none !important;
    display: inline-block;
  }

  .wc-layered-nav-rating {
    position: relative;
    padding-left: 23px;
    font-size: 12px;
    line-height: 2;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 14px;
      height: 14px;
      background-color: #efefef;
      border-radius: 5px;

      @include absolute(5px,null,null,0);
    }

    &.chosen {
      &:before {
        background-color: $primary-color;
      }

      &:after {
        color: white;
        font-size: 10px;

        @include absolute(6px,null,null,2px);
        @include fa("\f00c");
      }
    }

    a:hover,
    &.chosen {
      .star-rating span {
        color: $primary-color;
      }
    }
  }
}

/* Widget layered nav filter */
.widget_layered_nav_filters {
  li.chosen {
    display: inline-block;
    border-bottom: none !important;
    margin: 0 5px 0 0 !important;
    padding: 5px 0 !important;
  }
}

/* Widget shopping cart */
.widget_shopping_cart {
  ul.cart_list li {
    padding: 4px 0;
    margin: 0;
    list-style: none;

    a {
      display: block;
      font-weight: 600;
    }

    img {
      float: right;
      margin-left: 4px;
      width: 60px;
      height: auto;
      box-shadow: none;
    }

    dl {
      margin: 0;
      padding-left: 1em;
      border-left: 2px solid rgba(0,0,0,.1);

      &:before, &:after {
        content: " ";
        display: table;
      }

      &:after {
        clear: both;
      }
    }

    dt, dd {
      display: inline-block;
      float: left;
      margin-bottom: 1em;
    }

    dt {
      font-weight: 700;
      padding: 0 0 .25em;
      margin: 0 4px 0 0;
      clear: left;
    }

    dd {
      padding: 0 0 .25em;

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cart_list li {
    padding-left: 2em !important;
    position: relative;
    padding-top: 0;

    &:first-child {
      a.remove {
        top: 5px;
      }
    }

    & + li {
      padding-top: 10px;

      a.remove {
        top: 10px;
      }
    }

    &:before, &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    a.remove {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .total {
    border-top: 3px double #ebe9eb;
    padding: 10px 0 0;

    strong {
      min-width: 40px;
      display: inline-block;
      color: #000;
    }
  }

  .buttons {
    text-align: center;

    &:before, &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    .button {
      min-width: 120px;
      margin: 0 5px;

      @extend .btn;
      @extend .btn-maxus;
      @extend .btn-sm;
      @extend .btn-rounded;
      @extend .btn-outline;
    }

    .wc-forward:not(.checkout) {
      @extend .btn-default;
    }

    .wc-forward.checkout {
      @extend .btn-primary;
    }
  }
}

/* Widget products - recent reviews - rated products - recently viewed products */
.widget_products,
.widget_recent_reviews,
.widget_top_rated_products,
.widget_recently_viewed_products {
  .product_list_widget {
    li {
      overflow: hidden;

      a {
        display: block;

        > img {
          width: 25%;
          margin-right: 20px;
          float: left;
        }
      }

      .product-title {
        vertical-align: top;
        font-weight: 600;
      }

      del {
        opacity: 0.5;
        margin-right: 5px;
        font-size: 12px;
      }

      ins {
        text-decoration: none;
        font-weight: 600;
      }
    }
  }
}

/* Widget newsletter */
.widget_newsletter {
  position: relative;

  input[type="text"],
  input[type="email"] {
    padding: 5px 0 !important;
    font-size: 11px;
    height: auto !important;
    border: none;
    border-bottom: 1px solid #e1e1e1;
    box-shadow: none;
    background: none;

    &:focus {
      border-bottom-color: #000;
    }
  }

  .btn-newsletter {
    padding: 0;
    font-size: 5px;
    background: none;
    border: none;
    color: $text-color;
    transform: translateY(-50%);

    @include absolute(50%,0);
  }
}

/* Widget socials */
.widget_socials {
  @include media("<md") {
    text-align: center;
  }

  .widget-title {
    @include media("<md") {
      display: none;
    }
  }

  a {
    display: inline-block;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

/* Widget paypal */
.widget_paypal {
  @include media("<md") {
    text-align: center;
  }
}
