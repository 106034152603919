.alert {
  font-size: 12px;
  text-transform: uppercase;

  .close {
    top: 0;
    right: -12px;
  }

  .alert-link {
    text-decoration: underline;
    color: white;
  }
}
