// TEAMS
.maxus-team {}

.maxus-team__wrap {
  .slick-arrow {
    margin-top: -80px;
    z-index: 9;
    width: 64px;
    height: 64px;
    background-color: white !important;

    @include vhidden();
  }

  .slick-prev {
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .slick-next {
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:hover .slick-arrow {
    @include vdisplay();
  }
}

.maxus-team__item {
  position: relative;
  transition: all .3s ease;

  &:hover {
    .maxus-team__img:after {
      @include vdisplay();
    }

    .maxus-team__name {
      color: $primary-color;
    }
  }
}

.maxus-team__title {
  font-size: 40px;
  margin-bottom: 50px;
  color: black;
}

.maxus-team__img {
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: 133.33333%;
  }

  &:after {
    content: "";
    border: 15px solid rgba(black,.5);
    transition: all .3s ease;

    @include overlay(white,.3,1);
    @include vhidden();
  }

  img {
    width: 100%;

    @include absolute(0,0,0,0);
  }
}

.maxus-team__info {
  padding: 35px 0;
}

.maxus-team__job {
  margin-bottom: 0;
  font-size: 11px;
  color: #959595;
}

.maxus-team__name {
  color: black;
  margin-bottom: 20px;
  font-size: 18px;
  transition: all .3s ease;
}

.maxus-team__email,
.maxus-team__phone {
  font-size: 13px;
  margin-bottom: 5px;
}
