// HEADER
.header-top {
  position: relative;
  padding: 10px 0;
  z-index: 999;

  &:not(.no-border) {
    border-bottom: 1px solid #e1e1e1;
  }
}

.pd-10 {
  padding: 10px 0;
}

.pd-20 {
  padding: 20px 0;
}

.header-top__item {
  position: relative;
  display: inline-block;
  line-height: 30px;

  &:not(:last-child) {
    margin-right: 25px;
  }

  .maxus {
    display: inline-block;
    width: 15px;
  }

  .maxus-bx-search .maxus {
    width: auto;
  }

  .maxus-bags {
    margin: 0;
    margin-left: 20px;

    > a {
      padding: 0;
    }

    .maxus {
      width: auto;
    }

    .maxus-bags__quantity {
      top: -2px;
      left: auto;
      right: -25px;
    }
  }

  > a {
    display: block;
    color: $text-color;

    &:hover {
      color: $primary-color;
    }
  }

  .dropdown-menu {
    margin-top: 0;
    padding: 5px 10px;
    border: none;
    min-width: 170px;
    cursor: default;
    box-shadow: 0px 30px 40px 0px rgba(34, 35, 40, 0.4);

    label {
      margin-bottom: 0;
    }

    .maxus {
      font-size: 18px;
      vertical-align: middle;
      width: 25px;
      margin-right: 3px;
      display: inline-block;
      text-align: center;
      color: $text-color;
    }
  }

  li {
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }

    > a {
      padding: 10px;
      color: #000;
    }

    > a:hover,
    > a:hover .maxus {
      background-color: #fff;
      color: $primary-color;
    }
  }
}

.header-bottom {
  display: none;
  border-bottom: 1px solid #e1e1e1;

  @include media(">=md") {
    display: block;
  }

  &.no-border {
    border-bottom: none;
  }

  .navbar__category {
    margin: 20px 0;

    li:not(:last-child) {
      margin-right: 40px;
    }
  }

  .maxus-bags {
    display: block;
    font-size: 16px;
    padding: 17px 0;
    margin: 0;
    cursor: default;
  }

  .maxus-bags__quantity {
    position: static;
    display: inline-block;
  }

  .maxus-bags__list {
    left: 0 !important;
    right: auto !important;
    margin-top: 0 !important;
  }

  .maxus-search-form {
    margin-top: 0;
    border-radius: 0;
    padding: 12px 0;
    padding-right: 0;
    padding-left: 40px;
    border-left: 1px solid #e1e1e1;

    input[type="text"] {
      border-radius: 0;
      border: none;
      box-shadow: none;
    }

    .btn-search {
      right: auto;
      left: 0;
      width: 40px;
      background: none;
      color: $text-color;
      border: none;
    }
  }
}

.header-affix {
  position: relative;
  z-index: 101;

  .navbar {
    @include media(">=md") {
      @include absolute(0,0,null,0);
    }
  }
}

.navbar {
  backface-visibility: hidden;
  background-color: #fff;
  margin-bottom: 0;
  transition: all .4s ease;

  &.affix {
    position: static !important;

    @include media(">=md") {
      position: fixed !important;
      width: 100%;
      background-color: #fff !important;
      box-shadow: 0px 0px 27px 0px rgba(17, 17, 17, 0.1) !important;
      z-index: 999;
    }
  }

  &.fixed {
    @include media(">=md") {
      position: fixed;
      width: 100%;
      z-index: 99;
    }
  }

  &.affix-top.navbar--shadow
  &.navbar--transparent.navbar--shadow {
    box-shadow: none !important;
  }
}

.navbar--shadow {
  box-shadow: 0px 0px 27px 0px rgba(17, 17, 17, 0.1) !important;
}

.navbar--transparent {
  background: transparent !important;
}

.navbar__item {
  display: inline-block;
  position: relative;
  z-index: 10;

  &:last-child {
    margin-right: 0;
  }
}

.maxus-bags,
.maxus-profile {
  display: inline-block;
  color: #000;
  font-size: 20px;
  cursor: pointer;
}

.maxus-menu-bars {
  margin: 24px 20px 24px 0;
  line-height: 15px;
  cursor: pointer;
  font-size: 13px;
  color: #000;

  &:hover {
    color: $primary-color !important;
  }
}

.maxus-logo {
  margin: 20px 0;

  a {
    display: inline-block;
  }
}

.maxus-bx-search {
  font-size: 20px;
  cursor: pointer;
  transition: all .25s ease;

  &:hover {
    color: $primary-color !important;
  }
}

.maxus-bags,
.maxus-profile {
  position: relative;
  margin: 0 10px 0 0;
}

.maxus-bags__quantity {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  background-color: $primary-color;

  @include absolute(-10px,null,null,-24px);
}

.maxus-profile {
  position: relative;

  > a {
    position: relative;
  }
}

.maxus-profile__status {
  display: block;
  width: 10px;
  height: 10px;
  background-color: $primary-color;
  border-radius: 50%;

  @include absolute(null,null,1px,-2px);
}

.maxus-profile__list {
  label {
    margin-bottom: 0;
  }

  > p {
    margin-bottom: 0;
    position: relative;
    border-bottom: 1px solid #ebebeb;

    > a {
      display: block;
      padding: 10px;
      color: #000;
    }

    > a:hover,
    > a:hover .maxus {
      color: $primary-color;
    }
  }

  .maxus {
    font-size: 18px;
    vertical-align: middle;
    width: 25px;
    margin-right: 3px;
    display: inline-block;
    text-align: center;
    color: $text-color;
  }
}

.maxus-bags,
.maxus-profile {
  .dropdown-menu {
    left: auto;
    right: 0;
    margin-top: 20px;
    padding: 5px 22px;
    border: none;
    min-width: 230px;
    cursor: default;
    box-shadow: 0px 30px 40px 0px rgba(34, 35, 40, 0.4);

    label {
      margin-bottom: 0;
    }

    .maxus {
      font-size: 18px;
      vertical-align: middle;
      width: 25px;
      margin-right: 3px;
      display: inline-block;
      text-align: center;
      color: $text-color;
    }
  }

  li {
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }

    > a {
      padding: 10px;
      color: #000;
    }

    > a:hover,
    > a:hover .maxus {
      background-color: #fff;
      color: $primary-color;
    }
  }
}

.maxus-wishlist-quantity {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  background-color: $primary-color;
  font-size: 9px;
  transform: translateY(-50%);

  @include absolute(50%,0);
}

.maxus-bags__list {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
  min-width: 300px !important;
}

.maxus-bags__wrap {
  max-height: 272px;

  .mCSB_scrollTools {
    right: -10px;
  }
}

.maxus-bags__item {
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
}

.maxus-bags__img {
  width: 70px;
  float: left;
  margin-right: 20px;

  a {
    position: relative;
    display: block;
    font-size: 0;
    background-color: #f0f0f0;

    &:before {
      @include paddingTop(300,300);
    }
  }

  img {
    width: 100%;

    @include absolute(0,0,0,0);
  }
}

.maxus-bags__info {
  width: 165px;
  float: left;
}

.maxus-bags__title {
  line-height: 1.4;
  margin-bottom: 5px;
}

.maxus-bags__price {
  font-size: 13px;
  margin-bottom: 0;

  ins {
    text-decoration: none;
  }
}

.maxus-bags__total {
  padding: 25px 0;

  p:not(:last-child) {
    margin-bottom: 20px;
  }

  .btn-maxus {
    min-width: 100px;
    margin: 0 5px;
  }

  .maxus-bags__price label {
  }

  .maxus-bags__amount {
    color: $brand-danger;
    font-weight: 700;
  }
}

.maxus-search-form {
  position: relative;
  padding-right: 50px;
  margin-top: 25px;
  border-radius: 20px;
  overflow: hidden;
  display: block;

  input[type="text"] {
    border-radius: 20px 0 0 20px;
  }

  .btn-search {
    padding: 0;
    border-radius: 0;
    font-size: 20px;
    width: 50px;
    height: 40px;
    text-align: center;
    transform: translateY(-50%);

    @include absolute(50%,0);
  }
}

.navbar--category-search {
  .navbar__category {
    margin-left: -50px;
  }

  .btn-search {
    border-radius: 0 20px 20px 0;
  }
}

.navbar__relative {
  position: relative;
}

.navbar__category,
.navbar__category--standard {
  font-size: 16px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    position: relative;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 20px;
    }

    > a {
      display: block;
      color: #000;
      white-space: nowrap;
    }

    &:hover,
    &.active {
      > a {
        color: $primary-color;
      }
    }

    &:hover > .navbar__sub-category,
    &:hover > .navbar__sub-category-icon,
    &:hover > .navbar__sub-category-image {
      margin-top: 0;

      @include vdisplay();
    }
  }
}

.navbar__category--standard {
  position: absolute;
  top: 0;
  width: 100%;
}

.navbar__category--icons,
.navbar__category--images {
  position: absolute;
  width: 100%;
  top: 0;
  padding: 0 150px;
  z-index: 9;

  > ul > li {
    position: static !important;
  }
}

.navbar__sub-category {
  z-index: 9;
  min-width: 210px;
  margin-top: 70px;
  text-transform: none;
  transition: all .5s ease;

  @include absolute(null,null,null,-15px);
  @include vhidden();

  ul {
    background-color: #fff;
    margin-top: 15px !important;
    padding: 5px 15px !important;
    box-shadow: 0px 30px 40px 0px rgba(34, 35, 40, 0.4);
  }

  li {
    display: block !important;
    margin: 8px 0 !important;
  }

  li:hover > a,
  li.active > a {
    color: $brand-danger !important;
  }

  li:after {
    display: none !important;
  }
}

.navbar__sub-category-icon {
  background-color: #fff;
  height: 100vh;
  margin-top: 70px;
  transition: all .5s ease;

  @include absolute(null,0,null,0);
  @include vhidden();

  .maxus-icon-wrapper {
    overflow: inherit;
    white-space: normal;
    margin: 120px 0;
    margin-bottom: -1px;
  }

  .maxus-icon {
    float: left;
    margin-right: 0 !important;
    padding: 35px;
    border-top: 1px solid #e1e1e1;

    &:nth-child(6n+1) {
      border-left: none;
    }

    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        border-top-color: transparent;
      }
    }

    .maxus {
      font-size: 120px;
    }
  }

  .maxus-icon + .maxus-icon {
    margin-left: 0 !important;
  }
}

.navbar__sub-category-image {
  background-color: #fff;
  height: 100vh;
  margin-top: 70px;
  transition: all .5s ease;

  @include absolute(null,0,null,0);
  @include vhidden();

  .maxus-icon-wrapper {
    overflow: inherit;
    white-space: normal;
    margin: 70px 0;

    &:hover img {
      opacity: .3;
    }
  }

  .maxus-icon {
    position: relative;
    width: 100% !important;
    padding: 0 !important;
    margin-top: 20px;

    a {
      display: block;
    }

    img {
      width: 100%;
      transition: all .3s ease;
    }

    &:hover img {
      opacity: 1;
    }
  }

  .maxus-icon__title {
    font-size: 24px;

    @include absolute(30px,null,null,30px);
  }
}

.navbar__sub-category-megamenu {
  > li {
    display: block;
  }

  a {
    color: #d0d0d0 !important;
    padding: 5px 0;

    @include media(">=1460px") {
      font-size: 18px;
    }

    &:hover {
      color: $primary-color !important;
    }
  }
}

.navbar__menu {
  text-transform: uppercase;
  font-size: 12px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    position: relative;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background-color: #8ea6e6;
      transform: translateX(-50%);
      transition: all .4s ease;

      @include absolute(null,null,0,50%);
    }

    > a {
      display: block;
      color: #000;
      padding: 5px 0;
    }

    &:hover:after,
    &.active:after {
      width: 100%;
    }

    &:hover,
    &.active {
      > a {
        color: $primary-color;
      }
    }

    &:hover > .navbar__sub-category {
      margin-top: 0;

      @include vdisplay();
    }
  }
}

.navbar__label {
  font-size: 9px;
  text-transform: uppercase;
  color: #fff;
  width: 35px;
  height: 14px;
  line-height: 15px;
  border-radius: 10px;
  text-align: center;
  background-color: $brand-danger;

  @include absolute(-15px);
}

.maxus-information {
  position: relative;

  &:not(:last-child) {
    margin-right: 25px;
  }
}

.maxus-information__avatar {
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
  }

  .fa {
    font-size: 16px;
  }
}

.maxus-information__name {
  font-size: 11px;
}

.navbar__controls {
  padding: 45px 0;
  z-index: 2;
  background-color: #fff;

  @include absolute(0,0,0,0);
}

.navbar__container {
  z-index: 1;
  width: 335px;
  background-color: #fff;
  transition: all .5s ease;

  @include absolute(0,null,0,-200px);
  @include vhidden();

  @include media(">=tn") {
    width: 360px;
  }

  &.open {
    left: 80px;

    @include vdisplay();
  }

  .maxus-search-form {
    margin-top: 0;
    margin-bottom: 50px;
    padding: 0;
    border-radius: 0;

    input[type="text"] {
      padding-left: 30px;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #e1e1e1;
      box-shadow: none;

      &:focus {
        border-color: #000;
      }
    }

    .btn-search {
      left: 0;
      right: auto;
      background: none;
      border: none;
      width: 20px;
      color: $text-color;
    }
  }

  .navbar__category {
    font-size: 24px;
    margin-bottom: 50px;

    li {
      margin-right: 0;
      display: block;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover > a {
        color: $brand-danger;
      }
    }
  }

  .maxus-socials {
    label {
      margin-right: 5px;
    }

    a {
      margin-right: 5px;
    }
  }

  .maxus-bags__price {
    color: #000;
  }

  .maxus-profile__list {

  }
}

.navbar__wrap {
  padding: 100px 0;

  @include absolute(0,0,0,0);

  .mCSB_scrollTools {
    right: -10px;
  }

  @include media(">=xs") {
    margin: 100px 95px 100px 25px;
  }

  .maxus-bags__list {
    @include media("<xs") {
      min-width: 0 !important;

      .maxus-bags__total,
      .maxus-bags__price {
        text-align: left;
      }
    }
  }

  .maxus-profile__list,
  .maxus-search-form,
  .navbar__category,
  .maxus-socials {
    @include media("<xs") {
      width: 200px;
    }
  }
}

.navbar__bottom {
  transform: translateX(-50%);

  @include absolute(null,null,40px,50%);

  .maxus-bags, .maxus-profile {
    margin: 0;
    display: block;
  }

  .maxus-bags {
    margin-bottom: 15px;
  }
}

// HEADER 1
.navbar--default {
  // border-bottom: 1px solid #e1e1e1;

  .maxus-menu-bars,
  .maxus-logo,
  .maxus-bx-search {
    display: inline-block;
    color: #000;
    font-size: 20px;
    cursor: pointer;
  }

  .maxus-menu-bars {
    font-size: 13px;
  }
}

// HEADER 2
.navbar--category {
  .maxus-logo {
    margin-top: 35px;
  }

  .maxus-bags {
    margin-top: 32px;
  }

  .navbar__category {
    margin: 35px 0;
  }
}

// HEADER 5
.navbar--menu {
  background-color: #f4f4f4;

  .maxus-menu-bars {
    margin: 40px 0;
  }

  .navbar__menu {
    margin: 35px 0;
    padding-right: 50px;
  }

  .maxus-logo {
    margin: 35px 0;
    margin-left: -60px;
  }

  .maxus-bx-search {
    margin: 30px 0;
  }

  .maxus-bags {
    margin-top: 5px;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .maxus-signin {
    margin-top: 30px;
  }

  .btn-maxus {
    min-width: 105px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

// HEADER 6
.navbar--all {
  border-bottom: 1px solid #e1e1e1;

  .maxus-menu-bars {
    display: block;
    margin: 0;
    padding: 37px 0;
    border-right: 1px solid #e1e1e1;
    text-align: center;
  }

  .maxus-logo {
    margin: 30px 0 0;
    padding-left: 10px;
  }

  .navbar__category {
    margin: 30px 0 0;
    font-size: 14px;

    li:not(:last-child) {
      margin-right: 40px;
    }
  }

  .maxus-information {
    margin-top: 20px;
  }
}

// HEADER 8
.full-page {
  position: relative;
  height: 100vh;
  background-color: #fff;

  .container > * {
    padding-left: 80px;
  }
}

.navbar--full-page {
  width: 80px;
  border: none;
  z-index: 999;

  @include fixed(0,null,0,0);
}

.fix-style-header {
  position: relative;
  height: 100%;
  margin: 0;

  > div,
  > nav {
    margin: 0 !important;
    z-index: 100;
    transform: translateY(-50%);

    @include absolute(50%,0,null,0);
  }
}

// RESPONSIVE
.header-desktop {
  display: none;

  @include media(">=md") {
    display: block;
  }
}

.header-mobile {
  display: block;

  @include media(">=md") {
    display: none;
  }
}

.navbar-mobile {
  padding: 25px 10px;
  position: relative;
  z-index: 101;

  @include media(">=sm") {
    padding: 25px;
  }

  &.affix {
    position: fixed;
    top: -1px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 27px 0px rgba(17, 17, 17, 0.1);
    z-index: 999;
  }
}

.navbar-mobile__item {
  display: inline-block;
  width: 50%;
  line-height: 30px;
  font-size: 20px;

  & + .navbar-mobile__item {
    margin-left: -5px;
  }
}

.navbar-mobile__search,
.navbar-mobile__control,
.navbar-mobile__cart,
.navbar-mobile__profile {
  display: inline-block;
  margin: 0 0 0 5px !important;
}

.navbar-mobile__search {
  margin-right: 5px !important;
}

.navbar-mobile__cart {
  .maxus-bags__quantity {
    font-size: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    top: -2px;
    left: -10px;
  }

  @include media("<380px") {
    .maxus-bags__list {
      padding: 10px !important;
      min-width: 280px !important;
      right: -50px;
    }
  }
}

.sidr {
  font-family: $font-gotham;
  font-size: 14px;

  header {
    position: relative;
  }

  ul {
    text-transform: uppercase;

    li {
      position: relative;
    }
  }

  ul li a > span {
    padding: 0;
  }
}

.sidr-class-title-menu {
  display: block;
  padding: 10px 15px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
}

.sidr-class-close-menu {
  display: inline-block;
  font-size: 30px;
  color: #fff;
  width: 50px;
  height: 50px;
  text-align: center;
  border-left: 1px solid #1a1a1a;

  @include absolute(0,0);

  &:before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: #4d4d4d;

    @include absolute(0,null,0,0);
  }
}

.sidr-class-sub-menu {
  display: none;

  ul {
    text-transform: none;
  }
}

.sidr-class-menu__label {
  display: none;
}

.sidr-class-sub-menu__icon {
  font-size: 9px;

  @include absolute(0,0);

  &.in {
    font-size: 13px;

    .maxus-arrow-angle-down:before {
      content: "\e904";
    }
  }
}
