// ABOUT
.maxus-about {}

.maxus-about__big-img {
  position: relative;

  @include media(">=md") {
    width: 786px;
    float: right;
  }

  @include media(">=lg") {
    width: 992px;
  }

  @include media(">=1366px") {
    width: 1075px;
  }

  @include media(">=1460px") {
    width: 1350px;
  }

  &:before {
    @include paddingTop(1350,940);
  }

  > img {
    width: 100%;

    @include absolute(0,0,0,0);
  }
}

.maxus-about__info {
  z-index: 1;
  padding: 15px;

  @include media(">=md") {
    width: 350px;
    padding: 50px 30px;

    @include absolute(0,null,null,-180px);
  }

  @include media(">=1366px") {
    width: 550px;
    padding: 120px 90px;
    left: -250px;
  }

  @include media(">=1460px") {
    padding: 140px 90px;
  }
}

.maxus-about__overlay {
  display: block;
  z-index: -1;

  @include absolute(0,0,0,0);
}

.maxus-about__image {
  margin-bottom: 25px;
}

.maxus-about__title {
  font-family: $font-family-base;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 25px;

  @include media(">=md") {
    font-size: 26px;
  }

  @include media(">=1366px") {
    font-size: 36px;
  }

  @include media(">=1460px") {
    font-size: 48px;
    margin-bottom: 40px;
  }
}

.maxus-about__meta {
  font-size: 16px;
  color: black;
  padding-left: 30px;
  display: block;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media(">=md") {
    display: -webkit-box;
    max-height: 120px;
  }

  @include media(">=1366px") {
    padding-left: 50px;
  }

  @include media(">=1460px") {
    padding-left: 80px;
  }
}

.maxus-about__content {
  color: black;
  padding-top: 60px;
}

.maxus-about__title-box {
  font-size: 40px;
  margin-bottom: 30px;

  @include media(">=md") {
    padding-left: 30px;
  }
}

.maxus-about__desc {
  color: $text-color;

  @include media(">=md") {
    padding-left: 30px;
  }
}

.maxus-about__desc.font-weight {
  color: #666;
}

.maxus-about__item {
  margin-bottom: 30px;

  @include media(">=md") {
    padding-right: 40px;
    margin-bottom: 0;
  }
}
