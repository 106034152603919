@font-face {
  font-family: 'Maxus-Store';
  src:  url('../fonts/Maxus-Store.eot?v9717z');
  src:  url('../fonts/Maxus-Store.eot?v9717z#iefix') format('embedded-opentype'),
    url('../fonts/Maxus-Store.ttf?v9717z') format('truetype'),
    url('../fonts/Maxus-Store.woff?v9717z') format('woff'),
    url('../fonts/Maxus-Store.svg?v9717z#Maxus-Store') format('svg');
  font-weight: normal;
  font-style: normal;
}

.maxus:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Maxus-Store' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.maxus-armchair:before {
  content: "\e900";
}
.maxus-arrow-angle-down:before {
  content: "\e901";
}
.maxus-arrow-angle-left:before {
  content: "\e902";
}
.maxus-arrow-angle-right:before {
  content: "\e903";
}
.maxus-arrow-angle-up:before {
  content: "\e904";
}
.maxus-arrow-soft-left:before {
  content: "\e905";
}
.maxus-arrow-soft-right:before {
  content: "\e906";
}
.maxus-arrow-sort:before {
  content: "\e907";
}
.maxus-arrow-sort-down:before {
  content: "\e908";
}
.maxus-arrow-sort-up:before {
  content: "\e909";
}
.maxus-bars:before {
  content: "\e90a";
}
.maxus-bottle:before {
  content: "\e90b";
}
.maxus-chair:before {
  content: "\e90c";
}
.maxus-chair-2:before {
  content: "\e90d";
}
.maxus-clock:before {
  content: "\e90e";
}
.maxus-close:before {
  content: "\e90f";
}
.maxus-close-circle:before {
  content: "\e910";
}
.maxus-coat-stand:before {
  content: "\e911";
}
.maxus-comment:before {
  content: "\e912";
}
.maxus-computer-screen:before {
  content: "\e913";
}
.maxus-email:before {
  content: "\e914";
}
.maxus-eye:before {
  content: "\e915";
}
.maxus-free-shipping:before {
  content: "\e916";
}
.maxus-gift-card:before {
  content: "\e917";
}
.maxus-globes:before {
  content: "\e918";
}
.maxus-grid-2-col:before {
  content: "\e919";
}
.maxus-grid-4-col:before {
  content: "\e91a";
}
.maxus-guarantee:before {
  content: "\e91b";
}
.maxus-headphone:before {
  content: "\e91c";
}
.maxus-home:before {
  content: "\e91d";
}
.maxus-ipod:before {
  content: "\e91e";
}
.maxus-lamp:before {
  content: "\e91f";
}
.maxus-lighting:before {
  content: "\e920";
}
.maxus-lighting-2:before {
  content: "\e921";
}
.maxus-list:before {
  content: "\e922";
}
.maxus-location-pin:before {
  content: "\e923";
}
.maxus-map-marker:before {
  content: "\e924";
}
.maxus-minus-circle:before {
  content: "\e925";
}
.maxus-mouse:before {
  content: "\e926";
}
.maxus-package:before {
  content: "\e927";
}
.maxus-payment:before {
  content: "\e928";
}
.maxus-phone:before {
  content: "\e929";
}
.maxus-plus-circle:before {
  content: "\e92a";
}
.maxus-power:before {
  content: "\e92b";
}
.maxus-search:before {
  content: "\e92c";
}
.maxus-settings:before {
  content: "\e92d";
}
.maxus-share:before {
  content: "\e92e";
}
.maxus-shelving:before {
  content: "\e92f";
}
.maxus-shopping-bag:before {
  content: "\e930";
}
.maxus-sliders:before {
  content: "\e931";
}
.maxus-smartphone:before {
  content: "\e932";
}
.maxus-speaker:before {
  content: "\e933";
}
.maxus-table:before {
  content: "\e934";
}
.maxus-user:before {
  content: "\e935";
}
.maxus-wardrobe:before {
  content: "\e936";
}
.maxus-windown:before {
  content: "\e937";
}
.maxus-wishlist:before {
  content: "\e938";
}

