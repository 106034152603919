// LOADING
.loader {
  z-index: 9999;
  background: #9C27B0;
  animation: hue 5s linear infinite;

  @include fixed(0,0,0,0);
}

.loader--done {
  display: none !important;
}

.loader__wrap {
  font-size: 30px;
  font-family: $font-2nd;
  color: white;

  @include center();
}

.loader__letter {
  text-transform: uppercase;
  color: #fff;
  font-family: "Helvetica", sans-serif;
  font-weight: bold;
  padding: 0 .2em;
  line-height: 1;
  position: relative;
  display: inline-block;
  animation: bounce .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) infinite alternate;

  @for $i from 0 through 6 {
    &:nth-child(#{$i + 1}) {
       animation-delay: $i * .05s;
    }
  }
}

@keyframes bounce {
  0%{transform: translateY(0px);}
  100% {transform: translateY(-1em);}
}

@keyframes hue {
  0% { filter: hue-rotate(0deg); }
  100% { filter: hue-rotate(360deg); }
}
