// NEWSLETTER
.maxus-newsletter,
.maxus-newsletter--left {
  position: relative;
  text-align: center;

  hgroup {
    margin-bottom: 40px;
  }

  .form-group {
    position: relative;

    p {
      position: relative;

      @include media(">=lg") {
        margin-bottom: 0;
        display: inline-block;
      }
    }

    input[type="text"],
    input[type="email"] {
      display: inline-block;
      background-color: white;
      border-radius: 21px;
      height: 42px;
      border: none;
      box-shadow: none;
      padding-left: 50px;
      vertical-align: middle;

      @include media(">=lg") {
        min-width: 240px;
        width: auto;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;

        @include media(">=1300px") {
          min-width: 245px;
        }
      }
    }
  }

  .btn {
    margin-left: -2px;
    height: 42px;
    display: inline-block;
    min-width: 125px;
    font-size: 14px;
    line-height: 15px;
    background-color: white;
    color: black;

    @include media(">=lg") {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    &:hover {
      background-color: $primary-color;
      color: white;
    }

    .maxus {
      font-size: 8px;
    }
  }
}

.maxus-newsletter--round-sm {
  .form-group {
    input[type="text"],
    input[type="email"] {
      border-radius: 5px;
    }
  }

  .btn {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}

.maxus-newsletter__wrap {
  position: relative;
  min-height: 300px;
  background-color: #c5e3fe;
}

.maxus-newsletter__content {
  padding: 55px 10px;

  @include media(">=1300px") {
    padding: 55px 65px;
  }

  &.text-left .maxus-newsletter__meta {
    padding: 0 25px 0 3px;
  }

  &.text-right .maxus-newsletter__meta {
    padding: 0 3px 0 25px;
  }
}

.maxus-newsletter__title,
.maxus-newsletter__meta {
}

.maxus-newsletter__title {
  font-size: 40px;
}

.maxus-newsletter__meta {
  font-size: 14px;
  color: black;
  margin-bottom: 0;

  @include media(">=md") {
    padding: 0 25px;
  }
}

.maxus-newsletter__icon {
  font-size: 16px;
  transform: translateY(-50%);

  @include absolute(50%,null,null,20px);

  @include media(">=md") {
    left: 30px;
  }
}

.maxus-newsletter--right {
  .col-md-offset-1 {
    @extend .col-md-offset-6;
  }
}

.maxus-newsletter--center {
  .maxus-newsletter__wrap {
    min-height: 420px;
  }

  @include media(">=md") {
    .row {
      @include absolute(0,0,0,0);
    }

    .col-md-offset-1 {
      margin: 0;

      @include center();
    }
  }

  .col-md-offset-1 {
    @extend .col-md-offset-3;
  }

  .maxus-newsletter__content {
    padding: 90px 0;

    @include media(">=lg") {
      padding: 117px 65px;
    }
  }
}
