// CUSTOMER SAY
.maxus-customersay {
  &.text-white-all * {
    color: white !important;

    .maxus-customersay__info:before {
      color: white;
    }
  }

  [data-init="slick"] .maxus-customersay__wrap {
    margin-bottom: 0;
  }

  .slick-dotted {
    @include media("<md") {
      * {
        text-align: center !important;
      }
    }

    &.slick-slider {
      margin-bottom: 60px;
    }
  }
}

.maxus-customer--left,
.maxus-customer--right {
  .maxus-customersay__info:before {
    @include media(">=md") {
      position: static;
      margin: 10px 15px 0 -30px;
    }
  }
}

.maxus-customer--left {
  * {
    text-align: left !important;
  }
}

.maxus-customer--right {
  * {
    text-align: right !important;
  }
}

.maxus-customersay__item {
  position: relative;
  color: #000;

  &.text-white {
    .maxus-customersay__info:before {
      color: white;
    }
  }
}

.maxus-customersay__wrap {
  position: relative;
  margin-bottom: 150px;
  padding: 90px 0 40px;

  @include media(">=md") {
    padding: 90px 0 65px;
  }
}

.maxus-customersay__info {
  position: relative;

  &:before {
    font-size: 17px;
    vertical-align: text-top;
    color: $primary-color;
    transform: translateX(-50%);

    @include fa("\f10d");
    @include absolute(-45px,null,null,50%);
  }

  &.text-white {
    color: white;
  }
}

.maxus-customersay__avatar {
  @include absolute(null,0,-110px,0);

  img {
    width: 67px;
    height: 67px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
}

.maxus-customersay__name {
  color: black;
  font-weight: 600;

  &.text-white {
    color: white;
  }
}

.maxus-customersay__country {
  font-size: 12px;

  &.text-white {
    color: white;
  }
}

.maxus-customersay__wrap[data-color-class],
.maxus-customersay__item--border {
  padding-left: 10px;
  padding-right: 10px;

  @include media(">=ss") {
    padding-left: 50px;
    padding-right: 50px;
  }

  @include media(">=sm") {
    padding-left: 10px;
    padding-right: 10px;
  }

  @include media(">=md") {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.maxus-customersay__item--border {
  border: 1px solid #e1e1e1;

  &:before {
    color: #e1e1e1;
  }
}

.maxus-customersay__item--full {
  .maxus-customersay__wrap {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: 50px;

    @include media(">=ss") {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    @include media(">=sm") {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }

    @include media(">=md") {
      padding-left: 120px !important;
      padding-right: 120px !important;
    }

    @include media(">=lg") {
      padding-left: 160px !important;
      padding-right: 160px !important;
    }
  }

  .maxus-customersay__info {
    @include media(">=md") {
      line-height: 72px;
    }

    @include media("<md") {
      font-size: 48px !important;
    }

    @include media("<sm") {
      font-size: 24px !important;
    }

    @include media("<ss") {
      font-size: 18px !important;
    }

    &:before {
      color: $text-color;
    }
  }

  .maxus-customersay__avatar {
    display: inline-block;
    margin-top: 30px;
    padding-left: 85px;

    @include relative(auto,auto,auto,auto);

    img {
      transform: translateY(-50%);

      @include absolute(50%,null,null,0)
    }

    &.no-avatar {
      padding-left: 0;

      img {
        display: none;
      }
    }
  }

  .maxus-customersay__name {
    text-transform: uppercase;
  }
}

.maxus-customersay.bg-none .slick-arrow {
  &:before {
    color: $text-color;
    opacity: .3;
  }

  &:hover:before {
    opacity: 1;
  }
}
