// SELECT
.select2-selection--single {
  border-radius: 5px !important;
  border: 1px solid #e1e1e1 !important;
  height: 40px !important;
  outline: none;
}

.select2-selection__rendered {
  line-height: 40px !important;
  padding-left: 20px !important;
  padding-right: 50px !important;
  color: #111 !important;
}

.select2-selection__arrow {
  width: 50px !important;
  height: 40px !important;
}

.select2-selection__placeholder {
  color: #111 !important;
}

.select2-selection__arrow {
  height: 38px !important;
  width: 50px !important;

  b {
    border: none !important;
    height: 38px !important;
    width: 50px !important;
    top: 0 !important;
    left: 0 !important;
    margin: 0 !important;
    text-align: center;
    line-height: 38px;
    font-size: 18px;
    color: #111;

    &:after {
      @include fa("\f107");
    }
  }
}

.select2-selection__clear {
  font-size: 18px;
}

.select2-container--open .select2-selection--single {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.select2-container--open .select2-selection__arrow b:after {

  @include fa("\f106");
}

.select2-container--open .select2-dropdown--above {
  top: -5px !important;
  border-top: 1px solid #e1e1e1 !important;
}

.select2-dropdown {
  border-color: #e1e1e1 !important;
  color: #111 !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  overflow: hidden !important;
}

.select2-search--dropdown {
  position: relative;

  &:after {
    color: #a1a6bb;
    transform: translateY(-50%);

    @include fa("\f002");
    @include absolute(50%,15px);
  }
}

.select2-search__field {
  border: none !important;
  box-shadow: none !important;
  padding-left: 15px !important;
  padding-right: 30px !important;
  color: black !important;

  @include placeholder(black !important);
}

.select2-results__option[role="treeitem"] {
  padding: 10px 20px !important;
}

.select2-results__option[role="group"] {
  .select2-results__group {
    padding: 10px 20px !important;
    border-top: 1px solid #e1e1e1;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
  }
}

.select2-results__option[aria-selected=true],
.select2-results__option--highlighted {
  background-color: #e8f8f0 !important;
  color: #111 !important;
}

.select2-results > .select2-results__options {
  max-height: 350px !important;
}

.select-border {
  .select2-results__option:not(:last-child) {
    border-bottom: 1px solid #e1e1e1;
  }

  .ui-menu-item {
    &:not(:last-child) {
      border-bottom: 1px solid #e1e1e1;
    }
  }
}

.select-margin.select2-selection--single {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.select-margin.select2-dropdown--below {
  border-top: 1px solid #e1e1e1 !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.maxus-select--sharp {
  .select2-selection--single,
  .custom-combobox {
    border-radius: 0 !important;
  }
}

.maxus-select--round {
  .select2-selection--single,
  .custom-combobox {
    border-radius: 21px !important;
  }
}

.maxus-select--shadow {
  .select2-selection--single {
    border: none !important;
    box-shadow: 0px 10px 25px 0px rgba(58, 64, 91, 0.15);
    transition: all .3s ease;
  }

  .select2-container--open .select2-selection--single {
    box-shadow: none !important;
    background: #f4f4f4 !important;
  }

  &.select2-dropdown--below {
    border: none !important;
    box-shadow: 0px 20px 40px 0px rgba(34, 35, 40, 0.25);
    border-top: 1px solid #f9f9f9 !important;
  }

  .custom-combobox {
    border: none !important;
    background: #fff !important;
    box-shadow: 0px 10px 25px 0px rgba(58, 64, 91, 0.15);
    transition: all .3s ease;
  }

  .custom-combobox-toggle {
    background: none;
  }
}

.maxus-select--standard {
  .select2-container--open {
    .select2-selection--single {
      border: none !important;
      box-shadow: 0px 10px 25px 0px rgba(58, 64, 91, 0.15);
      transition: all .3s ease;
    }
  }

  &.maxus-select--shadow {
    border-top-color: #e1e1e1 !important;
  }
}

.maxus-select__item {
  position: relative;
}

.maxus-select__color {
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  margin-right: 15px;
}

.round {
  border-radius: 50% !important;
}

.custom-combobox {
  position: relative;
  display: block;
  overflow: hidden;
  background: #f4f4f4;

  &:after {
    color: #3a405b;
    transform: translateY(-50%);

    @include fa("\f002");
    @include absolute(50%,null,null,15px);
  }
}

.custom-combobox-input,
.ui-autocomplete-input {
  outline: none;
  height: 40px;
  padding: 5px 40px;
  border: none;
  width: 100%;
  background: none;
  color: #111;
}

.custom-combobox-toggle {
  width: 42px;
  height: 42px;
  background: #e1e1e1;
  line-height: 42px;
  text-align: center;
  font-size: 18px;

  @include absolute(0,0,0);

  &:after {
    color: #111;

    @include fa("\f107");
  }

  &:focus {
    outline: none;
  }

  &.selected:after {
    content: "\f106";
  }
}

.maxus-autocomplete,
.ui-autocomplete {
  max-width: 305px;
  position: absolute !important;
  padding: 0;
  margin: 0;
  list-style: none;
  background: #fff;
  box-shadow: 0px 20px 40px 0px rgba(34, 35, 40, 0.25);
  border-top: 1px solid #f9f9f9;
  border-radius: 5px;
  overflow: hidden;

  .scroll-panel {
    max-height: 350px;
    overflow-y: auto;
  }

  .ui-menu-item {
    padding: 10px 20px;

    &:hover {
      background: #e8f8f0;
    }
  }

  .ui-menu-item-wrapper {
    cursor: default;
    color: #111;
  }
}

.ui-helper-hidden-accessible {
  display: none !important;
}
