// FEATURES
.maxus-feature__item {
  float: left;
  width: 100%;

  @include media(">=sm") {
    width: 33.333%;
  }

  &:hover {
    .maxus-feature__overlay {
      opacity: .7;
    }
  }

  .maxus-overlay {
    z-index: 2;
  }
}

.maxus-feature__wrap {
  position: relative;
  background-color: #f0f0f0;
  overflow: hidden;
  transition: all .3s ease;
  text-align: center;

  @include media(">=md") {
    padding: 0 30px;
  }

  @include media(">=1460px") {
    text-align: left;
    padding: 0 60px;
    max-height: 370px;
  }

  img {
    position: relative;
    z-index: 3;
    transform: translate(0px, 0px);
    transition: all .25s ease;

    @include media(">=1460px") {
      width: 300px;
      height: 370px;
    }
  }
}

.maxus-feature__info {
  z-index: 3;
  color: white;
  padding: 30px 15px;
  position: relative;
  border-top: 1px solid transparent;

  @include media(">=sm") {
    border-top: none;
    padding: 0 30px;
    width: 100%;
    transform: translateY(-50%);

    @include absolute(50%,0);
  }

  @include media(">=1460px") {
    width: 50%;
  }
}

.maxus-feature__title {
  font-size: 30px;
}

.maxus-feature__meta {
  max-height: 84px;
  display: none;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;

  @include media(">=md") {
    display: -webkit-box;
  }
}
