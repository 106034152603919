.pagination {
  position: relative;
  margin: 0;
  padding: 21px 0;

  > li:first-child,
  > li:last-child,
  .pagination-button {
    display: none !important;
    z-index: 9;
    padding: 0 1px;
    min-width: 200px;

    @include absolute(0,null,0);

    @include media(">=lg") {
      display: block !important;
    }

    a {
      display: block;
      height: 100%;
      line-height: 90px;
    }
  }

  > li:first-child {
    left: -350px;
  }

  > li:last-child {
    right: -350px;
  }

  > li > a,
  .pagination > li > span {
    min-width: 50px;
    height: 48px;
    line-height: 48px;
    padding: 0 10px;
    text-align: center;
    float: none;
    display: inline-block;
  }

  .active {
    > a,
    > a:hover,
    > a:focus,
    > span,
    > span:hover,
    > span:focus {
      margin: 0 10px;
      border-radius: 50%;
      box-shadow: 10px 17px 40px 0px rgba(168, 172, 185, 0.62);
    }
  }

  .pagination-button {
    font-size: 10px;
  }
}
