.maxus-collection__name {
  font-size: 30px;
  font-weight: 700;
  font-family: $font-3rd;

  @include media('>=xs') {
    font-size: 40px;
  }
}

.maxus-collection__description {
  color: $secondary-color;
  margin-bottom: 25px;

  @include media('>=xs') {
    margin-bottom: 55px;
  }
}

$font-size: 14px;
$line-height: 1.5;
$lines-to-show: 4;

.maxus-collection__description {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.maxus-collection__main {
  padding: 100px 20px 40px;
  text-align: center;

  margin-top: -70px;

  @include media('>=md') {
    padding-top: 150px;
    margin-top: -120px;
  }

  @include media('>=lg') {
    padding-top: 200px;
    margin-top: -170px;
  }
}

.maxus-collection__gallery img {
  width: 100%;
}

.maxus-collection {
  position: relative;

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  @include media ('>=md') {
    .slick-prev {
      left: -70px;
    }

    .slick-next {
      right: -70px;
    }
  }
}

// CSS .maxus-collection--moderm
.maxus-collection.maxus-collection--moderm {
  background-color: $maxus-bg-second;

  .maxus-title {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    top: -80px;

    @include media('>=md') {
      top: -120px;
    }

    @include media('>=lg') {
      top: 55px;
      padding-left: 8.33333%;
      text-align: left;
    }
  }

  .maxus-collection__main {
    text-align: left;
    padding: 30px;
    margin-top: 0;
  }

  @include media('<sm') {
    .slick-prev, .slick-next {
      display: none !important;
    }
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  @include media ('>=lg') {
    .slick-prev {
      left: 20px;
    }

    .slick-next {
      right: 20px;
    }
  }

  @include media('>=sm') {
    padding: 60px 0;
    max-height: 500px;

    .maxus-collection__main {
      width: 50%;
      float: left;
      padding: 0 15px 0 0;
    }

    .maxus-collection__gallery {
      padding-left: 15px;
      width: 48%;

      @include absolute(0,0,0,null);
      overflow: hidden;
    }
  }

  @include media('>=md') {
    margin-bottom: 150px;

    .maxus-collection__gallery {
      padding-left: 15px;
      width: 48%;

      top: -100px; // -40-60
      bottom: -100px; // -40-60
    }
  }

  @include media('>=lg') {

    padding-top: 130px;
    padding-bottom: 100px;
    margin-bottom: 190px;

    .maxus-collection__gallery {
      padding-left: 15px;
      width: 48%;

      top: -190px; // -130-60
      bottom: -160px; // -100-60
    }

    .maxus-collection__main {
      margin-top: 0;
    }
  }

  &:nth-child(even) {
    @include media('>=sm') {
      .maxus-collection__gallery {
        right: auto;
        left: 0;
        padding-left: 0;
        padding-right: 15px;
      }

      .maxus-collection__main {
        float: right;
        padding-right: 0;
        padding-left: 15px;
      }
    }
  }
}

// CSS .section-collection-detail
.section-collection-detail {
  h2 {
    font-weight: 400;
  }

  .overflow-hidden .pull-left.btn i {
    margin-left: 0;
    margin-right: 5px;
  }
}

.maxus-collection-detail {
  .maxus-filter-toggle-icon {
    float: none;
  }

  .overflow-hidden > a {
    margin-bottom: 20px;
  }

  @include media('>=xs') {

    > .overflow-hidden > a:last-child {
      float: right;
    }
  }

  @include media('>=sm') {
    > .overflow-hidden > a {
      margin-bottom: 0;
    }

    .maxus-filter-toggle-icon {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

// CSS .maxus-collection--standard
.maxus-collection.maxus-collection--standard {
  overflow: hidden;
  margin-bottom: -5px;

  .maxus-collection__name {
    margin-bottom: 40px;
  }

  .maxus-collection__description {
    margin-bottom: 35px;
    color: $third-color;
    font-size: 16px;
    height: 16px * 1.5 * 3; /* Fallback for non-webkit */
  }

  .maxus-collection__main {
    margin-top: 0;
    position: relative;
    padding: 80px 50px;
  }

  @include media('<md') {
    .slick-next,
    .slick-prev {
      display: none;
    }
  }

  @include media('>=1000px') {
    .maxus-collection__gallery,
    .maxus-collection__main {
      width: 50%;
      float: right;
      text-align: right;
      overflow: hidden;
    }

    .maxus-collection__main {
      .slick-next {
        right: 90px;
      }

      .slick-prev {
        right: 120px;
      }
    }
  }

  @include media('>=1460px') {
    .maxus-collection__gallery {
      width: 66.666667%;
    }

    .maxus-collection__main {
      width: 33.33333%;
      padding: 150px 90px 100px 70px;
    }
  }
}
