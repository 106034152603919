// NEWS STYLE 1
.maxus-news__item {
  width: 100%;
  position: relative;
  overflow: hidden;

  @include media(">=sm") {
    width: 50%;
  }

  &:after {
    content: "";
    padding-top: 74.84%;
    position: relative;
    display: block;
    background-color: #f0f0f0;
    z-index: -1;
  }

  &:hover {
    .maxus-news__overlay {
      opacity: .6;
    }
  }

  &[data-news-type] {
    .maxus-news__img {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.maxus-news__img {
  z-index: 1;
  position: absolute;

  a {
    display: block;
    height: 100%;
    font-size: 0;
  }

  img {
    height: 100%;
    width: 100%;
  }
}

.maxus-news__overlay {
  z-index: 2;
  transition: all .3s ease;

  @include absolute(0,null,0,0);
}

.maxus-news__content {
  z-index: 3;

  @include absolute(0,null,0,null);

  &.w-per-100 {
    width: 100%;
  }
}

.maxus-news__wrap {
  position: absolute;
}

.maxus-news__author,
.maxus-news__view {
  margin-left: 25px;

  label {
    font-weight: 300;
  }
}

.maxus-news__info a {
  font-size: 11px;
  color: #959595;

  &:hover {
    color: $primary-color;
  }
}

.maxus-news__desc {
  margin-bottom: 25px;
  display: none;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.maxus-news__item[data-news-type="full"] {
  a {
    color: white;
  }

  .maxus-news__overlay {
    background-color: rgba($primary-color, .8);
    right: 0;
  }

  .maxus-news__date-post {
    margin-bottom: 20px;

    @include media(">=ss") {
      margin-bottom: 50px;
    }
  }

  .maxus-news__content {
    left: 0;
    right: 0;
  }

  .maxus-news__wrap {
    padding: 30px;
    text-align: center;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);

    @include media(">=ss") {
      padding: 30px 60px;
    }

    @include media(">=md") {
      padding: 30px 90px;
    }
  }

  .maxus-news__title {
    font-size: 14px;

    @include media(">=xs") {
      font-size: 18px;
    }

    @include media(">=md") {
      font-size: 24px;
    }

    @include media(">=1300px") {
      font-size: 36px;
    }
  }
}

.maxus-news__item[data-news-type="part"] {
  .maxus-news__overlay {
    background-color: rgba(white, .8);
    width: 50%;
  }

  .maxus-news__date-post {
    margin-bottom: 15px;
  }

  .maxus-news__content {
    width: 50%;
    background-color: white;
  }

  .maxus-news__wrap {
    padding: 15px;
    top: 50%;
    transform: translateY(-50%);

    @include media(">xs") {
      padding: 30px;
    }
  }

  .maxus-news__title {
    font-size: 14px;
    margin-bottom: 40px;

    @include media(">=xs") {
      font-size: 16px;
    }

    @include media(">=md") {
      font-size: 18px;
    }

    @include media(">=1300px") {
      font-size: 24px;
    }
  }

  .maxus-news__desc {
    @include media(">=tn") {
      max-height: 65px;
      -webkit-line-clamp: 3;
      display: -webkit-box;
    }

    @include media(">=ss") {
      max-height: 85px;
      -webkit-line-clamp: 4;
    }

    @include media(">=sm") {
      display: none;
    }

    @include media(">=md") {
      display: -webkit-box;
    }

    @include media(">=lg") {
      max-height: 125px;
      -webkit-line-clamp: 6;
    }
  }

  &:hover {
    .maxus-news__overlay {
      width: 100%;
    }
  }
}

// NEWS STYLE 2
.maxus-news__item--fw {
  width: 100%;

  &:after {
    display: none;
  }

  &:nth-child(2n+2) {
    .maxus-news__overlay {
      right: auto;
      left: 0;
    }

    .maxus-news__img {
      float: none;

      @include media(">=580px") {
        float: right;
      }
    }

    .maxus-news__content {
      right: auto;
      left: 0;
    }
  }

  &:hover {
    .maxus-news__overlay {
      width: 100%;
    }
  }

  .maxus-news__overlay {
    right: 0;
    left: auto;
    width: 0;
  }

  .maxus-news__img {
    width: 100%;
    position: relative;

    @include media(">=580px") {
      width: 50%;
      float: left;
    }

    &:after {
      content: "";
      padding-top: 74.84%;
      position: relative;
      display: block;
      background-color: #f0f0f0;
      z-index: -1;
    }

    a {
      @include absolute(0,0,0,0);
    }
  }

  .maxus-news__content {
    width: 100%;
    background-color: white;
    position: relative;

    @include media(">=580px") {
      width: 50%;
      float: left;
    }

    &.w-per-100 {
      width: 100%;
      text-align: center;
    }
  }

  .maxus-news__wrap {
    padding: 15px;
    position: relative;

    @include media(">=xs") {
      padding: 30px;
    }

    @include media(">=1300px") {
      padding: 80px 100px 35px 40px;
    }
  }

  .maxus-news__date-post {
    margin-bottom: 10px;

    label {
      color: $text-color;
    }

    a {
      color: $text-color;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .maxus-news__title {
    font-size: 16px;
    margin-bottom: 20px;

    @include media(">=ss") {
      font-size: 18px;
    }

    @include media(">=sm") {
      font-size: 20px;
    }

    @include media(">=md") {
      font-size: 30px;
    }
  }

  .maxus-news__info {
    padding-top: 0;
    color: #000;

    @include media(">=lg") {
      padding-top: 20px;
    }
  }

  .maxus-news__desc {
    margin-bottom: 20px;
    display: block;

    @include media(">=tn") {
      max-height: 65px;
      -webkit-line-clamp: 3;
      display: -webkit-box;
    }

    @include media(">=580px") {
      display: none;
    }

    @include media(">=md") {
      display: -webkit-box;
    }

    @include media(">=1300px") {
      max-height: 125px;
      -webkit-line-clamp: 6;
    }
  }
}

// SEARCH BLOG
.navigation.posts-navigation {
  padding: 20px 0;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  text-align: center;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 1px;
    background-color: #e1e1e1;
    transform: translateX(-50%);

    @include absolute(0,null,0,50%);
  }

  .nav-links:before,
  .nav-links:after {
    content: " ";
    display: block;
  }

  .nav-links:after {
    clear: both;
  }

  .nav-previous, .nav-next {
    display: inline-block;
    text-transform: uppercase;
    transition: all .3s ease;

    &:hover {
      color: $primary-color;
    }
  }

  .nav-previous {
    float: right;

    &:after {
      margin-left: 5px;

      @include fa("\f101");
    }
  }

  .nav-next {
    float: left;

    &:before {
      margin-right: 5px;

      @include fa("\f100");
    }
  }
}

// NEWS DETAIL
.site-main .comments-title {
  margin-bottom: 25px;
}

.single-blog {
  margin-bottom: 50px;

  .entry-header {
    position: relative;

    .slick-arrow {
      z-index: 9;
      width: 64px;
      height: 64px;
      background-color: white !important;

      @include vhidden();
    }

    .slick-prev {
      left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .slick-next {
      right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:hover .slick-arrow {
      @include vdisplay();
    }
  }

  .maxus-news__date-post {
    margin: 30px 0;
    font-size: 12px;
  }

  .entry-title {
    font-size: 40px;

    @include media(">=sm") {
      font-size: 60px;
    }

    @include media(">=md") {
      font-size: 40px;
    }

    @include media(">=lg") {
      font-size: 60px;
    }
  }

  .entry-content {
    position: relative;
    font-size: 14px;

    @include media(">=md") {
      font-size: 16px;
    }
  }

  .entry-footer {
    .tags-links {
      margin-right: 10px;
    }
  }

  .single-blog__image {
    margin: 55px 0;

    @include media(">=md") {
      margin-right: -51%;
    }
  }
}

.back-top-post {
  font-size: 10px;
  margin: 50px 0 20px;
}

.back-top-post__icon {
  display: inline-block;
  font-size: 12px;
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 15px;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  margin-right: 10px;
}

.maxus-news__share-button {

  @include media(">=md") {
    text-align: center;
  }

  span.maxus-news__share-button--icon {
    width: auto;
    margin-right: 10px;

    @include media(">=md") {
      display: block;
      margin-right: 0;
    }
  }
}

.maxus-news__share-button--icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  font-size: 14px;
  border-radius: 50%;
  overflow: hidden;

  @include media(">=md") {
    display: block;
  }

  a {
    display: block;
  }
}

.sidebar-none {
  .maxus-news__share-button {
    margin-bottom: 20px;

    @include media(">=md") {
      z-index: 1;

      @include absolute(-10px,-220px);
    }
  }

  .maxus-news__share-button--icon {
    text-align: center;

    & + .maxus-news__share-button--icon {
      @include media(">=md") {
        margin-bottom: 10px !important;
      }
    }
  }
}

.sidebar-left,
.sidebar-right {
  .maxus-news__item--fw .maxus-news__wrap {
    padding: 35px;
  }

  .maxus-news__share-button {
    text-align: left;
    margin-bottom: 20px;
  }

  .maxus-news__share-button--icon {
    display: inline-block !important;
    margin-bottom: 0;
    text-align: center;

    & + .maxus-news__share-button--icon {
      margin-left: 10px;
    }
  }

  .single-blog > .row > .col-md-8 {
    width: 100%;
  }

  .single-blog__image {
    margin-right: 0;
  }
}

// POST CAROUSEL
.maxus-post-carousel {
  &[data-init="slick"] {
    .maxus-post-carousel__item {
      margin-bottom: 0;

      @include media(">=md") {
        padding: 85px 60px;
      }
    }
  }
}

.maxus-post-carousel__item {
  margin-bottom: 20px;
}

.maxus-post-carousel__img {
  @include media(">=ss") {
    float: left;
    width: 33.333%;
    margin-right: 20px;
  }

  @include media(">=sm") {
    width: 25%;
  }

  @include media(">=md") {
    margin-right: 0;
  }

  a {
    display: block;
    font-size: 0;
    position: relative;

    &:before {
      @include paddingTop(640,480);
    }
  }

  img {
    width: 100%;

    @include absolute(0,0,0,0);
  }
}

.maxus-post-carousel__info {
  @include media(">=md") {
    float: left;
    width: 75%;
  }
}

.maxus-post-carousel__wrap {
  padding-top: 20px;

  @include media(">=ss") {
    padding-top: 0;
  }

  @include media(">=md") {
    padding-left: 20px;
  }
}

.maxus-post-carousel__title {
  @include media("<md") {
    font-size: 24px !important;
  }
}

.maxus-post-carousel__desc {
  font-size: 16px;
}

.maxus-post-carousel__author {
  font-size: 12px;
  display: none;

  @include media(">=md") {
    display: block;
  }
}
