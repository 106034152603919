// CONTACT
.maxus-contact {
  text-align: center;
  padding: 90px 0 100px;
}

.maxus-contact--border-line {
  position: relative;

  &:before {
    content: "";
    display: none;
    border-left: 1px solid #e1e1e1;
    transform: translateX(-50%);

    @include absolute(0,null,0,50%);

    @include media(">=md") {
      display: block;
    }
  }
}

.maxus-contact__title {
  font-size: 30px;
  color: black;
  margin-bottom: 40px;
}

//CSS .maxus-contact-info
.maxus-contact-info__icon {
  font-size: 40px;
  color: $primary-color;
}

.maxus-contact-info__desc {
  font-size: 13px;
}

.maxus-contact-info {
  overflow: hidden;
  margin-bottom: 50px;

  @include media('>=sm') {
    > .clearfix {
      margin-bottom: -50px;
    }
  }

  .maxus-contact-info__item {
    border-top: 1px dashed #ebebeb;
    padding: 50px 30px;

    &:nth-child(1) {
      border-top: none;
    }

    @include media(">=sm") {
      float: left;
      width: 50%;
      position: relative;

      &:nth-child(1),
      &:nth-child(2) {
        padding-top: 0;
      }

      &:nth-child(2) {
        border-top: 0;
      }

      &:nth-child(2n+2) {
        border-left: 1px dashed #ebebeb;
      }
    }
  }
}

//CSS .maxus-contact-write
.maxus-contact-write {
  @include media(">=ss") {
    padding: 0 60px;
  }
}

.maxus-contact-write__form {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  min-height: 300px;
  margin-bottom: 45px;
  text-align: left;
}

.maxus-contact-write__item {
  float: left;
  width: 100%;
  padding: 10px 20px;

  @include media(">=sm") {
    width: 50%;
  }

  label {
    display: block;
    margin-bottom: 0;
    font-weight: 300;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    border: none;
    box-shadow: none;
    padding: 0;
    width: 100%;
    resize: none;
    height: auto;
    color: black;
  }

  &:first-child {
    @include media(">=sm") {
      border-right: 1px solid #e1e1e1;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #e1e1e1;
  }
}

.maxus-contact-write__item--block {
  width: 100%;
}

.maxus-contact-write__title {
  font-size: 11px;
  color: #959595;
}
