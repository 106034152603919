// FOOTER
.site-footer {
  padding-bottom: 30px;

  .widget + .widget {
    border-top: none;
    padding-top: 15px;
    margin-top: 0;
  }

  .widget-title {
    &:after {
      @include media(">=md") {
        display: none;
      }
    }

    &.collapsed {
      transition: all .4s ease;

      @include media("<md") {
        margin-bottom: 0;
      }
    }
  }

  .widget {
    .collapse {
      @include media(">md") {
        display: block;
        height: auto !important;
      }
    }

    ul li:not(:last-child),
    ol li:not(:last-child) {
      border-bottom: transparent;
    }

    &:first-child {
      padding-top: 0;

      @include media(">=md") {
        padding-top: 30px;
      }
    }

    &:last-child {
      margin-bottom: 0;
      padding-top: 0;

      @include media(">=md") {
        padding-top: 30px;
      }
    }
  }

  .widget_categories li:not(:last-child) {
    border-bottom: none !important;
    margin-bottom: 8px !important;
    padding-bottom: 8px !important;
  }

  .widget_socials a {
    color: $text-color;

    &:hover {
      color: $primary-color;
    }
  }
}

.border-footer {
  padding-bottom: 50px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e1e1e1;
}

.maxus-language {
  position: relative;
  display: inline-block;

  .dropdown-menu {
    left: auto;
    right: 0;
    top: auto;
    bottom: 100%;
    border-bottom: none;
    margin-top: auto;
    margin-bottom: 20px;
    padding: 5px 10px;
    min-width: 50px;
    cursor: default;
    box-shadow: none;

    li {
      position: relative;

      &:not(:last-child) {
        border-bottom: 1px solid #ebebeb;
      }

      > a {
        padding: 10px;
        color: #000;
      }

      > a:hover,
      > a:hover .maxus {
        background-color: #fff;
        color: $primary-color;
      }
    }
  }
}

.nav-footer {
  padding-top: 30px;

  @include media("<md") {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    position: relative;

    &:not(:last-child) {
      margin-right: 40px;
    }

    &.active a,
    &:hover a {
      color: $primary-color;
    }
  }
}

.footer-top {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 15px;

  @include media("<md") {
    display: none;
  }

  nav {
    margin-bottom: -1px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    position: relative;
    display: inline-block;
    margin: 0 20px;

    &:after {
      content: "";
      height: 1px;
      width: 0;
      background-color: #000;
      transition: all .3s ease;
      transform: translateX(-50%);

      @include absolute(null,null,0,50%);
    }

    &:hover, &.active {
      &:after {
        width: 100%;
      }
    }
  }

  a {
    color: #000;
    display: block;
    padding: 20px 0;
  }
}

.about-footer {
  padding-top: 35px;

  @include media("<md") {
    text-align: center;
    width: 100% !important;
    margin-right: 0 !important;
  }

  label {
    font-weight: 300;
    color: $text-color;
  }

  a {
    color: $text-color;

    &:hover {
      color: $primary-color;
    }
  }
}

.about-footer__title,
.about-footer__address,
.about-footer__phone,
.about-footer__email {
  margin-bottom: 0;
}

// FOOTER 1
.footer-1 {
  .widget-title {
    @include media(">=md") {
      display: none;
    }
  }

  .widget_socials a {
    color: #000;

    &:hover {
      color: $primary-color;
    }
  }

  .widget_paypal {
    margin-top: 20px;
  }
}

// FOOTER 2
.footer-2 {
  .widget_categories li {
    margin-bottom: 5px !important;
    padding-bottom: 5px !important;
  }

  .widget_socials {
    font-size: 16px;

    a {
      margin-right: 25px;
    }
  }
}

// FOOTER 3
.footer-3 {
  @include media(">=md") {
    .widget_socials {
      margin-top: 40px;
    }

    .widget_paypal {
      margin-top: 40px !important;
    }
  }
}

// FOOTER 4
.footer-4 {
  .widget-title {
    text-transform: none;
    margin-bottom: 10px;
  }

  .widget + .widget {
    padding-top: 10px;
    margin-top: 0;

    @include media(">=md") {
      padding-top: 35px;
      margin-top: 35px;
    }
  }

  .widget_categories li {
    margin-bottom: 0 !important;
  }
}

// FOOTER 5
.footer-5 {
  .widget-title {
    @include media(">=md") {
      display: none;
    }
  }

  .widget_categories li {
    margin-bottom: 0 !important;
  }
}

// FOOTER 6
.footer-6 {
  .nav-footer,
  .widget_socials {
    padding-top: 38px;
  }
}

// FOOTER 7
.footer-7 {
  .nav-footer li:not(:last-child) {
    margin-right: 30px;
  }

  .widget_categories {
    padding-top: 15px;

    li {
      display: inline-block;
      font-size: 10px;
      padding: 0 !important;
      margin: 0 10px !important;
    }
  }
}
