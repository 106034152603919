@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/Gotham-Light.eot');
  src: url('../fonts/gotham/Gotham-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gotham/Gotham-Light.otf') format('otf'),
    url('../fonts/gotham/Gotham-Light.woff') format('woff'),
    url('../fonts/gotham/Gotham-Light.ttf') format('truetype'),
    url('../fonts/gotham/Gotham-Light.svg#Gotham-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/Gotham-LightItalic.eot');
  src: url('../fonts/gotham/Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gotham/Gotham-LightItalic.otf') format('otf'),
    url('../fonts/gotham/Gotham-LightItalic.woff') format('woff'),
    url('../fonts/gotham/Gotham-LightItalic.ttf') format('truetype'),
    url('../fonts/gotham/Gotham-LightItalic.svg#Gotham-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/Gotham-Medium.eot');
  src: url('../fonts/gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gotham/Gotham-Medium.otf') format('otf'),
    url('../fonts/gotham/Gotham-Medium.woff') format('woff'),
    url('../fonts/gotham/Gotham-Medium.ttf') format('truetype'),
    url('../fonts/gotham/Gotham-Medium.svg#Gotham-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/Gotham-Bold.eot');
  src: url('../fonts/gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gotham/Gotham-Bold.otf') format('otf'),
    url('../fonts/gotham/Gotham-Bold.woff') format('woff'),
    url('../fonts/gotham/Gotham-Bold.ttf') format('truetype'),
    url('../fonts/gotham/Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/Gotham-BoldItalic.eot');
  src: url('../fonts/gotham/Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gotham/Gotham-BoldItalic.otf') format('otf'),
    url('../fonts/gotham/Gotham-BoldItalic.woff') format('woff'),
    url('../fonts/gotham/Gotham-BoldItalic.ttf') format('truetype'),
    url('../fonts/gotham/Gotham-BoldItalic.svg#Gotham-BoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/Gotham-Black.eot');
  src: url('../fonts/gotham/Gotham-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gotham/Gotham-Black.otf') format('otf'),
    url('../fonts/gotham/Gotham-Black.woff') format('woff'),
    url('../fonts/gotham/Gotham-Black.ttf') format('truetype'),
    url('../fonts/gotham/Gotham-Black.svg#Gotham-Black') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('../fonts/gotham/Gotham-Book.eot');
  src: url('../fonts/gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gotham/Gotham-Book.otf') format('otf'),
    url('../fonts/gotham/Gotham-Book.woff') format('woff'),
    url('../fonts/gotham/Gotham-Book.ttf') format('truetype'),
    url('../fonts/gotham/Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('../fonts/gotham/Gotham-BookItalic.eot');
  src: url('../fonts/gotham/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/gotham/Gotham-BookItalic.otf') format('otf'),
    url('../fonts/gotham/Gotham-BookItalic.woff') format('woff'),
    url('../fonts/gotham/Gotham-BookItalic.ttf') format('truetype'),
    url('../fonts/gotham/Gotham-BookItalic.svg#Gotham-BookItalic') format('svg');
  font-weight: 400;
  font-style: italic;
}
