.close {
  opacity: 0.9;
  font-weight: normal;
  font-size: 14px;

  &:hover,
  &:focus {
    opacity: 1;
    outline: none;
  }
}
