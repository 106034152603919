.breadcrumbs {
  position: relative;
  z-index: 99;

  .trail-items {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .trail-item {
    display: inline-block;
  }

  li + li:before {
    content: "/ ";
    padding: 0 5px;
    color: #000;
  }

  .active,
  .trail-end  {
    font-style: italic;
    color: #ff2947;
  }
}
