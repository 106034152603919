// TABS
.maxus-tab {}

.maxus-tab__controls {
  margin-bottom: 40px;

  @include media(">=md") {
    text-align: center;
  }

  .tab-collapse {
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    color: black;
    font-size: 18px;
    margin-bottom: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #e1e1e1;

    @include media(">=md") {
      display: none;
    }

    .fa {
      margin-right: 5px;
      width: 16px;
      text-align: center;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    &.collapse {
      display: none;

      @include media(">=md") {
        display: block;
        height: auto !important;
      }

      &.in {
        display: block;
      }
    }
  }

  li {
    display: block;
    margin-bottom: 0;
    font-size: 18px;
    border-bottom: 1px solid #e1e1e1;

    @include media(">=md") {
      font-size: 24px;
      display: inline-block;
      border-bottom: none;

      &:not(:last-child) {
        border-right: 1px solid #e1e1e1;
      }
    }
  }

  a {
    display: block;
    padding: 15px 25px;
  }

  .active a {
    color: $primary-color;
  }
}
