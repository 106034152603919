// section-heading
.section-heading {
  .bs-component {
    padding: 0 10px;
    margin-bottom: 30px;

    h1, h2, h3, h4, h5, h6 {
      line-height: 60px;
      margin: 0;
    }

    @include media('>=sm') {
      float: left;
      width: 50%;
    }

    @include media('>=lg') {

      &:nth-child(1) {
        width: 23.5%;
      }

      &:nth-child(2) {
        width: 25%;
      }

      &:nth-child(3) {
        width: 20%;
      }

      &:nth-child(4) {
        width: 31.5%;
      }
    }
  }
}

.bs-group--header {
  background-color: $maxus-bg;
  padding: 65px 0 75px;
  margin-bottom: 65px;
  border-radius: $border-radius-base;
  .bs-component {
    padding: 0 70px !important;
  }
}

// section-columns
.section-columns {
  .bs-component {
    padding: 10px;
  }
}

// section-paragraph
.section-paragraph {
  .bs-component {
    padding-left: 5px;
  }
}
