.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;

  .gallery-columns-2 & {
    max-width: 50%;
  }

  .gallery-columns-3 & {
    max-width: 33.33%;
  }

  .gallery-columns-4 & {
    max-width: 25%;
  }

  .gallery-columns-5 & {
    max-width: 20%;
  }

  .gallery-columns-6 & {
    max-width: 16.66%;
  }

  .gallery-columns-7 & {
    max-width: 14.28%;
  }

  .gallery-columns-8 & {
    max-width: 12.5%;
  }

  .gallery-columns-9 & {
    max-width: 11.11%;
  }
}

.gallery-caption {
  display: block;
}
