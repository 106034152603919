// Grid system
.main-layout {
  @include make-row;

  .content-area {
    @extend .col-md-9;
  }
  .widget-area {
    @extend .col-md-3;
  }

  .page-builder {
    @extend .col-md-12;
  }

  &.sidebar-right {
    //
  }

  &.sidebar-left {
    .content-area {
      @extend .col-md-push-3;
    }

    .widget-area {
      @extend .col-md-pull-9;
    }
  }

  &.sidebar-none {
    .content-area {
      float: none;
      width: 100% !important;
      margin-left: auto;
      margin-right: auto;
    }

    .widget-area {
      display: none;
    }
  }
}
