// Vertical centering.
// Note: You should set parent element: transform-style: preserve-3d;
// @see http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
@mixin vertical-align($position: relative) {
  top: 50%;
  position: $position;
  transform: translateY(-50%);
}

/// Horizontal, vertical or absolute centering.
@mixin center($width: null, $height: null, $zindex: null) {
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: $zindex;

  @if not $width and not $height {
    transform: translate(-50%, -50%);
  } @else if $width and $height {
    width: $width;
    height: $height;
    margin-top: -($width / 2);
    margin-left:-($height / 2);
  } @else if not $height {
    margin-left: -($width / 2);
    transform: translateY(-50%);
    width: $width;
  } @else {
    margin-top: -($height / 2);
    transform: translateX(-50%);
    height: $height;
  }
}
