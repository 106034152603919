// PRODUCTS
.products {
  list-style: none;
  padding: 0;
  margin: 0 -10px;

  &:before, &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  .product {
    padding: 0 10px;
    margin-bottom: 20px;

    &:nth-child(2n+2) {
      .maxus-product__item--list .maxus-product__image {
        background-color: #e9e9e9;
      }
    }
  }

  .colw2 {
    width: 100%;

    @include media(">=md") {
      width: 50%;
    }
  }
}

.maxus-product--slider {
  .slick-arrow {
    border: 1px solid #e1e1e1;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    transition: all .3s ease;

    &:hover,
    &:active,
    &:active:hover,
    &:focus,
    &:focus:hover {
      background: $brand-danger;
      border-color: $brand-danger;

      &:before {
        color: white;
      }
    }
  }

  .slick-prev {
    &:before {
      content: "\f104";
    }
  }

  .slick-next {
    &:before {
      content: "\f105";
    }
  }
}

.maxus-product__title {
  margin-bottom: 40px;
}

.maxus-product__item {
  position: relative;
  height: 100%;
  padding: 40px 0 30px;
  background-color: #EEEEEE;
  text-align: center;
  transition: all .3s ease;

  &:hover {
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 1, 0.2);

    .maxus-product__info:before,
    .maxus-product__info:after {
      width: 100%;
      transition: all .8s ease;
    }

    .maxus-product__wrap {
      @include vdisplay();
    }
  }

  .maxus-product__compare {
    display: none;
    z-index: 3;

    @include absolute(15px,15px);

    @include media(">=md") {
      display: block;
    }

    .custom-control-indicator {
      border-color: #959595;
    }

    .custom-control {
      padding-left: 16px;
    }
  }

  .maxus-product__image {
    position: relative;
    margin-bottom: 20px;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    img {
      width: 100%;

      @include absolute(0,null,null,0);
    }
  }

  .maxus-product__name,
  .maxus-product__price {
    padding: 0 10px;
  }

  .maxus-product__name {
    font-size: 16px;
    letter-spacing: 0;

    @include media(">=sm") {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .maxus-product__price {
    margin-bottom: 0;

    del, ins,
    > .maxus-product__amount > .woocommerce-Price-amount {
      display: inline-block;
      font-weight: 600;
      font-size: 20px;
      margin: 0 15px;
    }

    del {
      font-size: 16px;

      .maxus-product__symbol,
      .woocommerce-Price-currencySymbol {
        font-size: 11px;
      }
    }

    ins,
    > .maxus-product__amount > .woocommerce-Price-amount {
      text-decoration: none;
      color: #000;
    }
  }

  .maxus-product__symbol,
  .woocommerce-Price-currencySymbol {
    font-size: 14px;
    vertical-align: text-top;
  }

  .maxus-product__info {
    display: none;

    @include absolute(0,0,0,0);

    @include media(">=md") {
      display: block;
    }

    &:before,
    &:after {
      content: "";
      width: 0;
      transition: all .4s ease;

      @include absolute(0,null,0,null);
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

  .maxus-product__wrap {
    z-index: 3;
    color: white;
    width: 160px;
    height: 100px;
    transition: all .8s ease;

    @include center();
    @include vhidden();

    p {
      margin-bottom: 0;
      font-size: 20px;
    }

    a {
      color: white;

      &:hover {
        color: $primary-color;
      }
    }

    .btn-default {
      background-color: white;
      color: black !important;
      border-color: white;
      min-width: 125px;
      font-size: 11px;

      &:hover {
        background-color: $primary-color;
        color: white !important;
        border-color: $primary-color;
      }
    }
  }

  .added_to_cart {
    font-size: 10px;
    background-color: darken($primary-color,5%);
    color: #fff !important;
    padding: 3px 5px;
    z-index: 9;
    text-transform: uppercase;

    @include absolute(0,0);

    &:before {
      margin-right: 5px;
      font-size: 12px;

      @include fa("\f07a");
    }

    &:hover {
      background-color: $primary-color;
    }
  }

  .maxus-product__quick-view {
    font-size: 30px;
    vertical-align: middle;
    margin-left: 5px;
  }

  &.maxus-product__item[data-style="background"],
  &.maxus-product__item[data-style="overlay"] {
    .maxus-product__name,
    .maxus-product__price {
      position: relative;
      z-index: 1;
    }

    .maxus-product__info {
      position: static;

      &:before, &:after {
        display: none;
      }
    }

    .btn-default {
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 1, 0.3);
    }

    &:hover {
      a, .maxus-product__price, ins, .maxus-product__amount > .woocommerce-Price-amount {
        color: white;
      }
    }

    a:hover {
      color: $primary-color;
    }
  }

  &.maxus-product__item[data-style="overlay"] {
    &:after {
      content: "";
      background-color: rgba(0,0,0,.5);
      transition: all .3s ease;

      @include absolute(0,0,0,0);
      @include vhidden();
    }

    &:hover:after {
      @include vdisplay();
    }
  }

  &.maxus-product__item[data-style="background"] {
    .maxus-product__overlay {
      z-index: 0;
      transition: all .3s ease;

      @include absolute(0,0,0,0);
      @include vhidden();
    }

    &:hover {
      .maxus-product__overlay {
        @include vdisplay();
      }
    }
  }
}

.maxus-product__label {
  padding: 0px 10px;
  border-radius: 20px;
  z-index: 9;
  min-width: 60px;
  color: #fff;
  text-align: center;
  transform: translateX(-50%);

  @include absolute(null,null,null,50%);

  &:first-child,
  &.onsale {
    top: -10px !important;
    background-color: $primary-color;
  }

  &.onhot {
    top: 20px;
    background-color: $brand-danger;
  }
}

.maxus-product__item--list {
  @include media(">=lg") {
    padding: 0 60px;
  }

  .maxus-product__image {
    width: 100%;
    padding: 30px 0;
    margin-bottom: 10px;
    background-color: #f3f3f3;
    transition: all .3s ease;

    @include media(">=ss") {
      width: 160px;
      margin-bottom: 0;
      margin-right: 20px;
    }

    img {
      width: 100%;
    }
  }

  .maxus-product__content {
    width: 100%;
    text-align: center;

    @include media(">=ss") {
      width: auto;
      text-align: left;
    }
  }

  .maxus-product__info {
    margin-right: 30px;
    width: 100%;

    @include media(">=ss") {
      width: 210px;
    }

    @include media(">=sm") {
      width: 250px;
    }
  }

  .maxus-product__name {
    margin: 5px 0 15px;

    @include media(">=sm") {
      margin: 25px 0 30px;
    }
  }

  .star-rating {
    @include media(">=ss") {
      float: left;
    }

    &:before {
      color: $brand-danger;

      @include fa("\f006\f006\f006\f006\f006");
    }
  }

  .star-rating-view {
    font-size: 11px;
    margin-left: 10px;

    @include media(">=ss") {
      float: left;
    }

    strong {
      color: #000;
    }
  }

  details {
    font-size: 11px;

    summary {
      display: none;
    }

    p {
      margin-bottom: 0;
    }
  }

  .maxus-product__price {
    margin-top: 5px;
    width: 100%;

    @include media(">=ss") {
      width: auto;
    }

    @include media(">=sm") {
      margin-top: 21px;
      margin-right: 30px;
    }
  }

  ins, del {
    display: inline-block;

    @include media(">=ss") {
      display: block;
    }
  }

  ins {
    font-size: 18px;
    color: #000;
    text-decoration: none;

    @include media(">=ss") {
      margin-bottom: 15px;
    }

    @include media(">=sm") {
      margin-bottom: 30px;
    }

    .maxus-product__symbol,
    .woocommerce-Price-currencySymbol {
      font-size: 12px;
      vertical-align: text-top;
    }
  }

  del {
    font-size: 11px;
    background: url(../img/sale.png) no-repeat;
    padding-left: 40px;
  }

  .maxus-product__action {
    float: none !important;
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 13px;

    @include media(">=ss") {
      margin-bottom: 0;
    }

    @include media(">=sm") {
      float: left !important;
    }

    label {
      font-weight: 300;
      margin-bottom: 0;
      vertical-align: text-bottom;
    }

    a {
      line-height: 17px;
      font-size: 14px;
      display: inline-block;
      margin-left: 5px;
      color: #bbb;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .btn-maxus {
    min-width: 140px;
    font-size: 13px;
    text-transform: none;
    margin: 10px 0 15px;

    @include media(">=sm") {
      margin-top: 0;
    }
  }

  .maxus-product__control {
    display: none;

    @include media(">=sm") {
      display: block;
    }
  }

  .maxus-product__quick-view {
    font-size: 22px !important;
    vertical-align: bottom;
  }
}

.maxus-product__item--slider {
  position: relative;

  a {
    display: inline-block;
  }

  .maxus-product__image {
    transition: all .3s ease;

    @include media(">=ss") {
      float: left;
      width: 140px;
    }

    a {
      display: block;
      font-size: 0;
      color: #000;

      &:hover {
        color: $primary-color;
      }
    }

    img {
      width: 100%;
    }
  }

  .maxus-product__content {
    text-align: center;
    margin-bottom: 30px;

    @include media(">=ss") {
      float: left;
      width: 50%;
      text-align: left;
      margin-bottom: 0;
      padding-left: 20px;
    }
  }

  .maxus-product__name {
    font-size: 14px;
    line-height: 1.5;
    padding-top: 15px;
  }

  .maxus-product__price {
    font-size: 18px;
    color: #000;
  }

  ins, del {
    display: block;
  }

  ins {
    text-decoration: none;

    .maxus-product__symbol,
    .woocommerce-Price-currencySymbol {
      font-size: 12px;
      vertical-align: text-top;
    }
  }

  del {
    font-size: 14px;
    color: $text-color;
  }
}

.iCheckbox {
  display: inline-block;
  cursor: pointer;

  span {
    position: relative;

    &:before {
      font-size: 11px;
      color: white;
      transform: translateY(-50%);

      @include absolute(50%, 2px);
      @include fa("\f00c");
      @include vhidden();
    }

    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      border: 2px solid #d5d5d5;
      margin-left: 5px;
      border-radius: 3px;
    }
  }

  input:checked ~ span {
    &:before {
      @include vdisplay();
    }

    &:after {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}

// PRODUCT DETAIL
.maxus-productdetail {
}

.maxus-productdetail__header {}

.maxus-productdetail__preview {
  padding: 0 60px;

  &.maxus-slick-arrows-rounded {
    .slick-arrow {
      top: 50%;
      bottom: auto;
      margin-top: -12.5px;
    }

    .slick-prev {
      left: 0;
      right: auto;
    }

    .slick-next {
      right: 0;
    }
  }
}

.maxus-productdetail__big-image {
  background-color: #f3f3f3;

  img {
    width: 100%;
  }
}

.maxus-productdetail__thumbnail-image {
  margin: 25px 0;
  padding: 0 20px;

  .slick-list {
    padding: 0 0 30px 15px;
  }
}

.maxus-productdetail__thumbnail-item {
  float: left;
  padding: 10px 0;
  margin-right: 20px;
  width: 60px !important;
  background-color: #e1e1e1;
  position: relative;
  cursor: pointer;
  transition: all .3s ease;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  &:hover,
  &.slick-current {
    background-color: #f3f3f3;
    box-shadow: 0px 13px 27px 0px rgba(28, 30, 35, 0.3);
  }

  a {
    display: block;
    font-size: 0;
  }

  img {
    width: 100%;

    @include absolute(10px,null,null,0);
  }
}

.maxus-productdetail__summary {
  .star-rating {
    &:before {
      color: $brand-danger;

      @include fa("\f006\f006\f006\f006\f006");
    }
  }

  .star-rating-view {
    font-size: 11px;
    margin-left: 10px;

    @include media(">=ss") {
      float: left;
    }

    strong {
      color: #000;
    }
  }
}

.maxus-productdetail__rating-view {
  color: #000;
  font-size: 12px;
}

.maxus-productdetail__name {
  font-size: 28px;
  margin-bottom: 20px;

  @include media(">=sm") {
    font-size: 40px;
  }
}

.maxus-productdetail__price,
.maxus-productdetail__rating,
.maxus-productdetail__shipping {
  display: block;
  margin-right: 30px;
}

.maxus-productdetail__price {
  ins {
    font-size: 16px;
    text-decoration: none;
    color: #000;
    margin-right: 5px;
  }

  del {
    font-size: 12px;
  }
}

.maxus-productdetail__shipping {
  color: $primary-color;
  text-decoration: underline;
}

.maxus-productdetail__desc {
  padding-top: 20px;
  margin-bottom: 30px;
}

.maxus-productdetail__options {
  display: inline-block;
  vertical-align: middle;

  &:not(:last-child) {
    margin-right: 20px;
  }

  p {
    font-size: 11px;
  }
}

.maxus-productdetail__sale {
  color: #000;
  font-size: 12px;
  padding-top: 10px;

  .maxus-product__symbol,
  .woocommerce-Price-currencySymbol {
    font-size: 7px;
    vertical-align: text-top;
  }
}

.maxus-productdetail__action {
  padding-top: 20px;

  p {
    margin-bottom: 15px;
  }

  .btn-maxus {
    padding-top: 8px;
    padding-bottom: 5px;
  }

  .maxus {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 5px;
  }

  a {
    color: $text-color;

    &:hover {
      color: $primary-color;
    }
  }
}

.maxus-productdetail--fullwide {
  .slick-arrow {
    top: auto !important;
    bottom: -40px;
    width: 26px;
    height: 26px;
    line-height: 33px;
    z-index: 9;
    border: 2px solid $text-color;
    border-radius: 50%;

    &:before {
      color: $text-color;
      font-size: 16px;
    }

    &:hover {
      border-color: black;

      &:before {
        color: black;
      }
    }
  }

  .slick-prev {
    left: 0;

    &:before {
      content: "\f104";
    }
  }

  .slick-next {
    left: 35px;
    right: auto;

    &:before {
      content: "\f105";
    }
  }

  .maxus-productdetail__header {
    position: relative;

    &:before,
    &:after {
      display: none;
      content: "";
      z-index: -1;

      @include absolute(0,null,0,null);

      @include media(">=md") {
        display: block;
      }
    }

    &:before {
      left: 0;
    }

    &:after {
      left: 50%;
      right: 0;
      background-color: #f3f3f3;
    }
  }

  .maxus-productdetail__preview {
    padding: 60px 20px 30px;

    @include media(">=md") {
      width: 50%;

      @include absolute(0,null,0,0);
    }

    @include media(">=1300px") {
      padding: 60px 80px 0;
    }

    @include media(">=1460px") {
      padding: 60px 130px 0;
    }
  }

  .maxus-productdetail__big-image {
    background-color: transparent;
    padding: 45px 0 0;
    text-align: center;
  }

  .maxus-productdetail__summary {
    padding: 0 10px;

    @include media(">=md") {
      padding: 120px 60px;
    }
  }

  .maxus-productdetail__name,
  .maxus-productdetail__price ins {
    font-weight: 600;
  }
}

.maxus-productdetail__summary--fashion {
  width: 50%;
  float: right;
  padding: 120px !important;

  .star-rating {
    font-size: 18px;
  }

  .maxus-productdetail__desc {
    padding-top: 0;
  }

  .maxus-productdetail__options {
    &:not(:last-child) {
      margin-right: 30px;
      padding-right: 30px;
      border-right: 1px solid #e1e1e1;
    }
  }

  .maxus-productdetail__price {
    padding-top: 30px;

    ins {
      font-size: 36px;
    }

    .maxus-product__symbol,
    .woocommerce-Price-currencySymbol {
      font-size: 18px;
      vertical-align: text-top;
    }
  }

  .btn-element {
    padding: 12px 95px 12px 50px;
  }
}

.maxus-productdetail__wrap {
  &.maxus-productdetail__wrap--bg {
    background-color: #f3f3f3;
    min-height: 0;
  }

  &[data-init="isotope"] {
    background-color: #f3f3f3;
  }
}

.maxus-productdetail__content {
  padding: 50px 0;
  max-width: 100%;
  overflow-x: auto;

  .tab-collapse {
    display: block;
    width: 100%;
    text-align: left;
    text-transform: uppercase;
    font-family: $font-2nd;
    font-size: 16px;
    color: black;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 10px;
    margin-bottom: 0;

    @include media(">=md") {
      display: none;
    }

    .fa {
      margin-right: 5px;
      width: 13.72px;
    }
  }

  .tab-controls {
    position: relative;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 0;
      font-size: 14px;

      @include media(">=md") {
        height: auto !important;
      }

      &.collapse {
        @include media(">=md") {
          display: block !important;
        }
      }

      &.collapse.in {
        height: auto;
      }
    }

    li {
      display: block;
      transition: all .3s ease;
      border-bottom: 1px solid #e1e1e1;

      @include media(">=md") {
        display: inline-block;
        border-bottom: none;
      }

      &.disabled {
        opacity: .6;
      }
    }

    a {
      display: block;
      padding: 8px 25px 7px;
      line-height: 26px;

      @include media(">=md") {
        border: 2px solid transparent;
        border-radius: 100px;
      }
    }

    .dropdown-menu {
      margin-top: 1px;
      padding: 9.5px 0;
      width: 100%;

      li {
        display: block;
        margin: 0;
        border: none;
      }

      a {
        padding: 5px 10px;
        border-radius: 0;
        text-transform: none;
      }
    }

    .divider {
      border-bottom: 1px solid #e1e1e1;
      margin: 9.5px 0 !important;
    }
  }

  .tab-content {
    padding: 10px;
    overflow: auto;

    @include media(">=md") {
      padding: 55px 0 0 60px;
    }
  }
}

.maxus-productdetail__item,
.grid-sizer {
  float: left;
  max-width: 960px;
}

.maxus-productdetail__content--fashion-style {
  .tab-controls li {
    &:not(:last-child) {
      @include media(">=md") {
        margin-right: 17px;
      }
    }

    a {
      border-color: #e1e1e1;
    }
  }
}

.maxus-productdetail__content--tech-style {
  background-color: #f3f3f3;
  padding: 50px 15px;

  @include media(">=md") {
    min-width: 960px;
    padding: 50px 60px;
  }

  .tab-controls {
    a {
      color: $text-color;
    }

    ul {
      @include media("<md") {
        font-size: 14px !important;
      }
    }

    li a:hover,
    li.active a {
      color: #000;
    }
  }

  .tab-content {
    padding: 40px 0;
  }

  table {
    width: 700px;

    @include media(">=md") {
      width: 100%;
    }

    tr {
      border-top: 1px solid #e1e1e1;
    }

    td {
      vertical-align: top;
      padding: 20px;

      &:first-child {
        color: #000;
        font-size: 16px;
        width: 160px;
        border-right: 1px solid #e1e1e1;
      }
    }
  }
}

.maxus-productdetail__content--tech-style-2 {
  background-color: #fff;
  padding: 0;

  .tab-controls {
    padding: 40px 20px 0;
  }

  .tab-collapse {
    border-bottom-color: transparent;
    padding-bottom: 0;
  }

  .tab-controls ul.text-center {
    @include media("<md") {
      text-align: left;
    }
  }

  table {
    @include media("<lg") {
      width: 100%;
    }

    @include media("<ss") {
      width: 600px;
    }
  }

  .w-per-50 {
    @include media("<lg") {
      width: 100%;
      padding: 0 20px !important;
    }
  }

  .maxus-productdetail__item {
    @include media("<lg") {
      display: none;
    }
  }
}

// PRODUCT HOT
.maxus-product-hot {}

.maxus-product-hot__overlay {
  z-index: -1;

  @include absolute(0,0,0,0);
}

.maxus-product-hot__label {
  display: block;
  width: 185px;
  height: 520px;
  background: url(../img/hot.png) no-repeat center;
  transform: translateY(-50%);

  @include absolute(50%,-92.5px);
}

.maxus-product-hot__category {
  position: relative;
  padding: 15px;
  font-size: 18px;

  @include media(">=sm") {
    font-size: 24px;
  }

  @include media(">=md") {
    float: left;
    width: 50%;
    font-size: 30px;
    padding: 30px;
  }

  @include media(">=lg") {
    min-height: 775px;
  }

  p {
    position: relative;
    padding-left: 30px;

    &:hover a, &.current a, &.active a {
      color: white;

      &:before {
        border-color: #fff;
      }
    }
  }

  a {
    color: black;

    &:before, &:after {
      transform: translateY(-50%);

      @include absolute(50%);
    }

    &:before {
      content: "";
      display: block;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid #000;
      border-radius: 50%;
    }

    &:after {
      font-size: 14px;
      left: 8px;

      @include fa("\f105");
    }
  }
}

@include media(">=1200px") {
  .maxus-product-hot__wrap {
    @include center();
  }
}

.maxus-product-hot__list {
  position: relative;
  text-align: center;
  color: #000;
  overflow: hidden;

  @include media(">=lg") {
    float: right;
    width: 50%;
    min-height: 775px;
  }

  a {
    display: inline-block;

    &:hover {
      color: #fff;
    }
  }
}

.maxus-product-hot__item {
  padding: 30px 0 50px;
  transition: all .3s ease;
  display: none;
  overflow: hidden;

  @include media(">=lg") {
    width: 450px;
    height: 0;
    padding: 0;
  }

  &:hover .maxus-product-hot__img .btn {
    bottom: 100px;

    @include vdisplay();
  }

  &.in {
    @include media(">=lg") {
      height: 650px;
    }
  }
}

.maxus-product-hot__img {
  position: relative;

  img {
    max-height: 400px;
  }

  .btn {
    transform: translateX(-50%);

    @include absolute(null,null,-20px,50%);
    @include vhidden();
  }
}

.maxus-product-hot__name {
  font-size: 48px;
  line-height: 60px;
}

.maxus-product-hot__desc {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 40px;

  p {
    margin-bottom: 0;
  }
}

.maxus-product-hot__price {
  font-size: 30px;

  ins {
    text-decoration: none;
  }
}

.maxus-product-hot__symbol {
  font-size: 18px;
  vertical-align: text-top;
}

// PRODUCT SC
.maxus-product-sc {
  position: relative;

  &.maxus-product-sc--bg.white {
    color: #fff;

    a {
      color: #fff;

      &:hover {
        color: #000;
      }
    }

    .btn-maxus {
      color: #000;

      &:hover {
        color: #fff;
      }
    }
  }

  &.maxus-product-sc--bg.black {
    color: #000;

    a {
      color: #000;

      &:hover {
        color: #fff;
      }
    }
  }

  &.maxus-product-sc--right {
    .maxus-product-sc__info {
      right: 0;
    }
  }

  &.maxus-product-sc--center-top {
    .maxus-product-sc__img {
      position: static;
    }

    .maxus-product-sc__img img {
      position: static;
      width: 100%;
      transform: translateY(0);
    }

    .maxus-product-sc__wrap {
      height: auto;

      @include absolute(0,0,0,0);
    }

    .maxus-product-sc__info {
      @include media(">=lg") {
        transform: translate(-50%,0);
        top: 110px;
        left: 50%;
      }
    }
  }

  &.maxus-product-sc--center-bottom {
    .maxus-product-sc__img img {
      top: 0;
      transform: translateY(0);
    }

    .maxus-product-sc__info {
      @include media(">=lg") {
        transform: translate(-50%,0);
        top: auto;
        bottom: 130px;
        left: 50%;
      }
    }
  }

  .btn-maxus {
    &:hover,
    &:active,
    &:active:focus,
    &:active:hover,
    &:focus {
      background-color: #000 !important;
      border-color: #000 !important;
    }
  }
}

.maxus-product-sc__wrap {
  position: relative;

  @include media(">=lg") {
    height: 940px;
  }
}

.maxus-product-sc__img {
  display: none;

  @include absolute(0,0,0,0);

  @include media(">=lg") {
    display: block;
  }

  img {
    transform: translateY(-50%);

    @include absolute(50%);
  }
}

.maxus-product-sc__info {
  @include media("<lg") {
    width: auto !important;
    text-align: left;
    padding: 150px 30px;
  }

  @include media("<ss") {
    padding: 80px 15px;
  }

  @include media(">=lg") {
    padding: 0;
    transform: translateY(-50%);

    @include absolute(50%);
  }

  .btn-prefix {
    color: #fff !important;
  }
}

.maxus-product-sc__category {
  margin-left: 3px;
  margin-bottom: 5px;
}

.maxus-product-sc__title {
  margin-bottom: 30px;

  @include media("<lg") {
    font-size: 48px !important;
  }

  @include media("<sm") {
    font-size: 36px !important;
  }

  @include media("<ss") {
    font-size: 30px !important;
  }
}

.maxus-product-sc__desc {
  margin-bottom: 25px;
}

// PRODUCTS SLIDER HOT
.maxus-product-slide-hot {
  .slick-arrow {
    z-index: 9;
    width: 64px;
    height: 64px;
    background-color: white !important;
    transform: translateY(-50%);

    @include media(">=lg") {
      @include vhidden();
    }
  }

  .slick-prev {
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .slick-next {
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:hover .slick-arrow {
    @include vdisplay();
  }
}

.maxus-product-slide-hot__item {
  width: 50%;
  float: left;
  text-align: center;
}

.maxus-product-slide-hot__wrap {
  position: relative;
  padding: 90px 0;

  img {
    display: inline-block;
  }

  .btn-maxus {
    margin-top: 70px;
    transition: all .5s ease;

    @include center();
    @include vhidden();
  }

  &:hover {
    .btn-maxus {
      margin-top: 0;

      @include vdisplay();
    }
  }
}

// PRODUCT FEATURES
.maxus-product-features {
  position: relative;
}

.maxus-product-features__wrap {
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: 405px;

    @include media(">=sm") {
      @include paddingTop(1920,1080);
    }
  }
}

.maxus-product-features__info {
  width: 280px;
  z-index: 2;
  padding: 20px 0;
  color: #000;

  @include center();

  @include media(">=ss") {
    width: 400px;
  }

  @include media(">=md") {
    color: $text-color;
    transform: translateY(-50%);

    @include absolute(50%,null,null,140px);
  }
}

.maxus-product-features--right .maxus-product-features__info {
  @include media(">=md") {
    left: auto;
    right: 140px;
  }
}

.maxus-product-features__desc {
  @include media("<sm") {
    padding: 0 !important;
    font-size: 14px !important;
  }
}
