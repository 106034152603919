// Visually hidden element.
@mixin vhidden() {
  opacity: 0;
  visibility: hidden;
}

// Visually display element.
@mixin vdisplay {
  opacity: 1;
  visibility: visible;
}

// FontAwesome
%fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin fa($icon) {
  content: $icon;
  @extend %fa;
}

// FontMaxus
%famaxus {
  font-family: "Maxus-Store";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin famaxus($icon) {
  content: $icon;
  @extend %famaxus;
}

// Overlay background
@mixin overlay($bg: black, $alpha: 0.5, $z-index: 5) {
  @include absolute(0,0,0,0);
  background-color: rgba($bg, $alpha);
  z-index: $z-index;
}

// PADDING TOP IMAGE
@mixin paddingTop($width, $height) {
  content: "";
  display: block;
  padding-top: percentage($height/$width);
}