h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  letter-spacing: 1px;
}

// Page header
.page-header {
  padding-bottom: 6px;
  margin-bottom: 30px;
}

.maxus-info {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1.2px;
}

// Typography Edit
.section-button {
  .bs-component__content {
    text-align: center;

    @include media(">=sm") {
      text-align: left;
    }
  }

  .btn-group {
    margin-bottom: 30px;

    @include media(">=sm") {
      margin-right: 30px;
    }
  }
}

.maxus-social {
  a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: white;
    text-align: center;

    display: inline-block;
    border-radius: 50%;
    transform: translateZ(0);
    transition: all .25s ease;

    & + a {
      margin-left: 10px;
    }
  }
}

