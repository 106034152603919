// COMMENTS
.comments-title, .comments__title {
  margin-bottom: 50px;
}

.comments-title {
  font-size: 24px;
}

.comment-list,
.children {
  list-style: none;
  padding: 0;
  margin: 0;
}

.comment .comment-respond {
  margin-top: 30px;

  .comments-title {
    margin-bottom: 20px;
  }
}

.comment-body {
  position: relative;
  padding: 1.75em 0 3em;
  border-top: 1px solid #e3e3e3;

  .maxus-star {
    padding-left: 65px;
    margin-top: -30px;
    margin-bottom: 15px;

    @include media(">=ss") {
      padding-left: 80px;
      margin-top: -15px;
    }
  }
}

.comment-author {
  display: inline-block;

  .avatar {
    float: left;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: top;
    width: 55px;
    height: 55px;

    @include media(">=xs") {
      margin-right: 20px;
    }

    @include media(">=sm") {
      margin-right: 25px;
    }
  }

  .fn {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    @include media(">=ss") {
      margin-top: 20px;
    }
  }
}

.comment-metadata {
  display: inline-block;
  font-size: 10px;

  @include media(">=ss") {
    font-size: 12px;
  }

  &:before {
    content: "/";
    margin: 0 3px;
  }

  a {
    color: #b3b3b3;

    &:hover {
      color: $primary-color;
    }
  }

  .edit-link {
    @include absolute(null,15px,42px,null);

    @include media(">=ss") {
      right: auto;
      left: 185px;
    }

    .comment-edit-link:before {
      margin-right: 5px;

      @include fa("\f044");
    }
  }
}

.reply {
  padding-left: 15px;
  padding-right: 15px;
}

.comment-content, .reply {
  padding-top: 10.5px;

  @include media(">=ss") {
    padding-left: 80px;
  }
}

.comment-reply-link,
.comment-edit-link {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 3px;
  color: black !important;

  &:before {
    margin-right: 5px;

    @include fa("\f112");
  }

  &:hover {
    color: $primary-color !important;
  }
}

.children {
  padding-left: 0;

  @include media(">=xs") {
    padding-left: 50px;
  }

  @include media(">=sm") {
    padding-left: 80px;
  }
}

.comment-form {
  input[type="text"],
  input[type="email"],
  input[type="url"],
  textarea {
    box-shadow: none;

    @include placeholder(#717171);
  }
}

.rating-form-title {
  display: block;

  @include media(">=ss") {
    display: inline-block;
    margin-right: 10px;
  }
}

.comments-custom__list,
.comments-custom__respond {
  padding: 50px 15px;

  @include media(">=ss") {
    padding: 50px 30px;
  }

  @include media(">=md") {
    float: left;
    width: 50%;
    height: 750px;
  }

  @include media(">=lg") {
    padding: 75px 70px;
  }
}

.comments-custom__list {
  background-color: white;
}

.comments-custom__respond {
  background-color: #f3f3f3;

  input[type="text"],
  input[type="email"],
  textarea {
    background: none;
    border: none;
    border-bottom: 1px solid #e1e1e1;
    border-radius: 0;
    box-shadow: none !important;
    padding: 12px 0;
    transition: all .3s ease;

    &:focus {
      background: white;
      padding: 12px;
      border-bottom-color: $primary-color;
    }
  }

  .maxus-star {
    margin-right: 15px !important;
  }
}

.comments-custom__title {
  font-size: 24px;
  margin-bottom: 50px;

  @include media(">=ss") {
    margin-bottom: 70px;
  }
}

.comments-custom__wrap,
.comments-custom__children {
  list-style: none;
  padding: 0;
  margin: 0;
}

.comments-custom__wrap {
  @include media(">=md") {
    height: 505px;
    overflow: hidden;
    overflow-y: auto;
  }
}

.comments-custom__children {
  padding-left: 35px;

  @include media(">=ss") {
    padding-left: 80px;
  }

  .comments-custom__comment {
    margin-top: 20px;
  }
}

.comments-custom__comment {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.comments-custom__body {
  position: relative;
}

.comments-custom__meta {
  float: left;

  @include media(">=ss") {
    margin-right: 15px;
  }
}

.comments-custom__content {
  padding-left: 35px;
  padding-top: 35px;

  @include media(">=ss") {
    padding-left: 80px;
    padding-top: 0;
  }
}

.comments-custom__content-wrap {
  border: 1px solid #e1e1e1;
  padding: 20px 15px;

  .maxus-star {
    margin-bottom: 25px;
  }
}

.comments-custom__metadata {
  margin-bottom: 10px;

  .fn {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.comments-custom__metatime {
  display: inline-block;
  font-size: 10px;

  @include media(">=ss") {
    font-size: 12px;
  }

  &:before {
    content: "/";
    margin: 0 3px;
  }

  a {
    color: #b3b3b3;

    &:hover {
      color: $primary-color;
    }
  }
}

.comments-custom__reply {
  padding-top: 10px;
}

.comments-custom__edit-link {
  margin-left: 20px;

  .comment-edit-link:before {
    content: "\f044";
  }
}
