// PAGER BLOG
.maxus-pager-blog {
  overflow: hidden;
}

.maxus-pager-blog__item {

  @include media(">=sm") {
    width: 50%;
    float: left;
  }

  &:hover .maxus-pager-blog__overlay {
    opacity: .8;
  }
}

.maxus-pager-blog__wrap {
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: 47.9167%;
    background-color: #f0f0f0;
  }

  img {
    width: 100%;

    @include absolute(0,null,null,0);
  }

  a {
    display: block;
    color: black;

    @include media(">=ss") {
      display: inline-block;
    }

    &:hover {
      color: white;
    }
  }

  &.w-per-100 {
    width: 100%;
  }
}

.maxus-pager-blog__overlay {
  z-index: 1;
  background-color: rgba(#f9f9f9, .9);
  transition: all .3s ease;

  @include absolute(0,0,0,0);

  &.pager-blog__none {
    background: none;
  }
}

.maxus-pager-blog__info {
  color: black;
  padding: 15px;
  z-index: 2;

  @include absolute(0,0,0,0);

  @include media(">=ss") {
    padding: 40px;
  }

  @include media(">=sm") {
    padding: 30px;
  }

  @include media(">=md") {
    padding: 50px;
  }

  @include media(">=lg") {
    padding: 100px 70px;
  }

  @include media(">=1460px") {
    padding: 100px 230px 100px 70px;
  }
}

.maxus-pager-blog__title {
  font-size: 24px;
  margin-bottom: 0;
  padding: 0 30px;
  text-align: center;
  transform: translateY(-50%);

  @include absolute(50%,0,null,0);

  @include media(">=ss") {
    margin-bottom: 10.5px;
    position: static;
    padding: 0;
    text-align: left;
    transform: translateY(0);
  }

  @include media(">=sm") {
    margin-bottom: 0;
    position: absolute;
    padding: 0 30px;
    transform: translateY(-50%);
  }

  @include media(">=md") {
    margin-bottom: 10.5px;
    position: static;
    padding: 0;
    transform: translateY(0);
  }

  @include media(">=1460px") {
    font-size: 36px;
  }
}

.maxus-pager-blog__desc {
  max-height: 63px;
  display: none;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;

  @include media(">=ss") {
    display: -webkit-box;
  }

  @include media(">=sm") {
    display: none;
  }

  @include media(">=md") {
    display: -webkit-box;
  }
}

.maxus-pager-blog__author {
  display: none;
  margin-bottom: 0;

  @include media(">=ss") {
    display: block;
  }

  @include media(">=sm") {
    display: none;
  }

  @include media(">=md") {
    display: block;
  }
}
