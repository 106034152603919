// PAGE TITLE
.maxus-page-title {
  position: relative;
  background-color: #f0f0f0;
}

.maxus-page-title--white {
  color: white;

  a {
    color: white;

    &:hover {
      color: $primary-color;
    }
  }

  .maxus-page-title__info {
    color: white;
  }
}

.maxus-page-title__main {
  position: relative;
  padding: 75px 0;
}

.maxus-page-title__blog-layout {
  padding-bottom: 604px;
}

.maxus-page-title__info {
  font-size: 30px;
  text-align: center;
  padding: 55px 0 68px;

  @include media(">=ss") {
    font-size: 40px;
  }

  @include media(">=sm") {
    font-size: 60px;
  }
}

@each $bg in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 {
  .bg-#{$bg} {
    background-image: url(../img-demo/pages-title/#{$bg}.jpg);

    background-size: cover;
    background-position: 50% 0;
    background-color: transparent;
    background-attachment: scroll;
  }
}
