// STAR RATINGS
.maxus-star {
  unicode-bidi: bidi-override;
  direction: rtl;
  text-align: left;
}
.maxus-star > span {
  display: inline-block;
  position: relative;
  width: 1.1em;
  color: $brand-warning;
  font-size: 12px;
}

.maxus-star > span.active:before,
.maxus-star > span.active ~ span:before {
  content: "\f005";
  font-family: FontAwesome;
  position: absolute;
  font-size: 12px;
  color: $brand-warning;
}

.maxus-star.maxus-star--rating > span:hover:before,
.maxus-star.maxus-star--rating > span:hover ~ span:before {
  content: "\f005";
  font-family: FontAwesome;
  position: absolute;
  color: $brand-warning;
}

// CSS .rating
.rating {
  margin-bottom: 20px;

  .maxus-star {
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
  }

  @include media('>=xs') {
    float: left;
    padding-top: 15px;
    margin-bottom: 0;
  }
}

// .maxus-star--danger
.maxus-star--danger {
  > span,
  > span.active:before,
  > span.active ~ span:before,
  &.maxus-star--rating > span:hover:before,
  &.maxus-star--rating > span:hover ~ span:before {
    color: $brand-danger;
  }
}

// .maxus-star--comment
.maxus-star--comment {
  > span {
    color: #bcbcbc;
  }
}
