.post-grid__media .maxus-video {
  position: relative;

  &:after {
    padding-top: 53.12%;
  }

  > img {
    @include absolute(0,null,null,0);
    width: 100%;
  }
}

.post-grid__cat {
  font-size: 16px;
  text-transform: uppercase;
  display: block;
}

.post-grid__title {
  font-size: 30px;
  letter-spacing: 0;
  font-family: $font-3rd;
}

.post-grid__main {
  padding: 50px 30px;
  text-align: center;
}

.post-grid__comment {
  margin-left: 25px;

  .maxus-comment {
    display: inline-block;
    vertical-align: middle;
    margin-top: 2px;
  }
}

@include media('>=lg') {
  .post-grid {
    overflow: hidden;

    .post-grid__media,
    .post-grid__main {
      float: right;
    }

    .post-grid__media {
      width: 66.66667%;
    }

    .post-grid__main {
      width: 33.33333%;
    }
  }

  .post-grid__main {
    padding: 50px;
  }

  .post-grid__main {
    text-align: right;
  }
}

@include media('>=1460px') {

  .post-grid__title {
    font-size: 48px;
    margin-right: -5px;
  }

  .post-grid__main {
    padding: 145px 95px;
  }
}
